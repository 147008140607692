/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import SettingTable from "../ManageSettings/SettingTable";
import AuthContext from "../../../../utils/secure-route/AuthContext";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import { toast, ToastContainer } from "react-toastify";
import "./Lottery.css";
import ReusableModal from "../ManageSettings/ReusableModal";
import ManageGameModal from "../ReusableForm/ManageGameModal";
import Swal from "sweetalert2";

function LotteryInventory() {
  const [games, setGames] = useState([]);
  const [activatedGames, setActivatedGames] = useState([]);
  const { Get, Post, Delete } = useApi();
  const [showModal, setShowModal] = useState(false);
  const [lotteryGameAdd, setLotteryGamesAdd] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [scanNumber, setScanNumber] = useState("");

  const [isGameFound, setIsGameFound] = useState(false);
  const [gameNo, setGameNo] = useState();
  const [bookNo, setBookNo] = useState();
  const [gamePrice, setGamePrice] = useState();
  const [gameTotalTickets, setGameTotalTickets] = useState();
  const [gameTotalPrice, setGameTotalPrice] = useState();
  const [gameId, setGameId] = useState();

  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [gameName, setGameName] = useState();
  let { user } = useContext(AuthContext);

  const [showModalGame, setShowModalGame] = useState(false); // Modal state
  const handleShowGame = () => setShowModalGame(true); // Show modal
  const handleCloseGame = () => {
    setShowModalGame(false);
    makeBlank();

  }; // Close modal
  const [lotteryGameData, setLotteryGameData] = useState(null);



  const [formData, setFormData] = useState({
    inventory_date: "",
    lottery_game: "",
    book_no: "",
    // game_name:""
  });

  const activateBook = async (data) => {
    await Get("isShiftActive").then(async (response) => {
      const payload = {
        game_no: data.game_no,
        book_no: data.book_no,
        active_date: selectedDate,
        status: "activated",
        activated_book_shift: response.shift_id,
      };
      await Post("updateGameAndInventory", payload).then((response) => {
        fetchGameData();
        toast.success("Book activated");
      });
    });
  };
  const columns = [
    { header: "Date", field: "inventory_date" },
    { header: "Game No", field: "game_no" },
    { header: "Game Name", field: "game_name" },
    { header: "None Active Books", field: "book_no" },
    { header: "Game Price", field: "game_price" },
    { header: "Total Value", field: "game_total_price" },
  ];
  const columns2 = [
    { header: "Date Added", field: "game_receive_date" },
    { header: "Scan No", field: "scan_no" },
    { header: "Game No", field: "game_no" },
    { header: "Game Price", field: "game_price" },
    { header: "Status", field: "status" },
  ];

  const storeId = user.store;
  function filterBooksByStatus(dataArray, transactionType) {
    return dataArray.filter((record) => record.status === transactionType);
  }
  const fetchGameData = async () => {
    try {
      const response = await Get("lotteryInventoryData");
      setGames(filterBooksByStatus(response, "non activated"));
      setActivatedGames(response);
    } catch (error) {
      console.error("Error fetching game data:", error);
    }
  };

  const fetchGameID = async () => {
    try {
      const response = await Get("lotteryGameData");
      setLotteryGamesAdd(response); // Store game options separately
    } catch (error) {
      console.error("Error fetching game data:", error);
    }
  };

  useEffect(() => {
    fetchGameData();
    fetchGameID();
    const today = new Date().toISOString().split("T")[0]; // Format today's date as YYYY-MM-DD
    setSelectedDate(today);
  }, []);
  useEffect(() => {
    fetchLotteryGameData();
  }, [gameNo]);

  const makeBlank = () => {
    setScanNumber("");
    setIsGameFound(false);
    setGameNo("");
    setBookNo("");
    setGameName("");
    setFormData({
      inventory_date: "",
      lottery_game: "",
      book_no: "",
      // game_name:""
    });
    setLotteryGameData({});
  };

  // Handle date change
  const handleDateChange = (e) => {
    setSelectedDate(e.target.value); // Update the state when the user selects a date
  };
  const handleShow = () => setShowModal(true);
  const handleClose = () => {
    setShowModal(false);
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  function getTicketWithGameAndBook(input) {
    // Convert the input to a string
    let str = input.toString().slice(0, 14);
    // Extract parts of the string
    let start = str.slice(0, 3); // First 3 digits
    let middle = str.slice(4, -4); // Middle part (excluding first 3 and last 3 digits)
    let end = str.slice(-3); // Last 3 digits
    // Combine with "-" separators
    let obj = {
      gameNo: start,
      bookNo: middle,
      ticket_no: end,
    };
    return obj;
  }

  const handleScanAddGame = async () => {
    // Find the ID that matches the entered game name

    const newGame = {
      inventory_date: selectedDate,
      lottery_game: gameId, // Use the matched game ID
      book_no: bookNo,
      game_name: gameName,
      store: storeId,
      scan_no: formatString(scanNumber),
    };

    function formatString(input) {
      // Convert the input to a string
      let str = input.toString().slice(0, 14);
      // Extract parts of the string
      let start = str.slice(0, 3); // First 3 digits
      let middle = str.slice(4, -4); // Middle part (excluding first 3 and last 3 digits)
      let end = str.slice(-3); // Last 3 digits
      // Combine with "-" separators
      return `${start}-${middle}-${end}`;
    }

    try {
      await Post("lotteryInventoryData", newGame)
        .then((resp) => {
          toast.success("Game added successfully");
          makeBlank();
        })
        .catch((err) => {
          makeBlank();
          console.error(err);
          toast.error("Unable to add game.");
        });
      await fetchGameData(); // Fetch the latest data immediately
    } catch (error) {
      makeBlank();
      toast.error("Error adding game");
    }
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    // Check if the user clicked the confirm button
    if (!result.isConfirmed) return; // Exit if the user cancels
    try {
      await Delete("lotteryInventoryData", id); // Call delete API with the game ID
      setGames((prevGames) => prevGames.filter((game) => game.id !== id));
      toast.success("Game deleted successfully");
    } catch (error) {
      toast.error("Error deleting game");
    }
  };

  const checkScannedNumber = async (GameNo) => {
    try {
      await Post("checkForGames", { game_no: GameNo }).then((resp) => {
        if (resp.status === 200) {
          let gameObj = resp.data[0];
          setIsGameFound(true);
          setGameNo(gameObj.game_no);
          setGameName(gameObj.game_name);
          setGamePrice(gameObj.value);
          setGameTotalTickets(gameObj.total_no_of_tickets);
          setGameTotalPrice(gameObj.total_value);
          setGameId(gameObj.id);
        }
      }); // Call your API to check for games
    } catch (error) {
      console.error("Error fetching game data:", error);

      setIsGameFound(false);
      handleShowGame();
      setScanNumber("");

      // toast.error("Error checking scanned number."); // Show an error toast if the API fails
    }
  };

  const checkScannedNumberManual = async (GameNo) => {
    try {
      if (GameNo.length < 3) return;
      await Post("checkForGames", { game_no: GameNo }).then((resp) => {
        let gameObj = resp.data[0];
        setIsGameFound(true);
        setGameNo(gameObj.game_no);
        setGameName(gameObj.game_name);
        setGamePrice(gameObj.value);
        setGameTotalTickets(gameObj.total_no_of_tickets);
        setGameTotalPrice(gameObj.total_value);
        setGameId(gameObj.id);
      });
    } catch (error) {
      console.error("Error fetching game data:", error);

      setIsGameFound(false);
      handleShowGame();
      setScanNumber("");

      // toast.error("Error checking scanned number."); // Show an error toast if the API fails
    }
  };

  const handleScanNumberChange = (e) => {
    // 40301394400391010070000000068
    // 403-139440-039
    const newScanNumber = e.target.value;
    setScanNumber(newScanNumber);
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    if (newScanNumber.length >= 14) {
      // const _gameNo =
      //   newScanNumber.substring(4, 7).toString().replace(/^0+/, "") || "0";
      // const _bookNo = newScanNumber.substring(7, 14);

      const _gameNo = getTicketWithGameAndBook(newScanNumber).gameNo;
      const _bookNo = getTicketWithGameAndBook(newScanNumber).bookNo;
      // alert(JSON.stringify(getTicketWithGameAndBook(newScanNumber)))
      setGameNo(_gameNo);
      setBookNo(_bookNo);
      const timeout = setTimeout(() => {
        checkScannedNumber(_gameNo);
      }, 500);
      setDebounceTimeout(timeout);
    }
  };

  const fetchLotteryGameData = async () => {
    try {
      await Get("lotteryGameData")
        .then((resp) => {
          if (resp && Array.isArray(resp)) {
            setLotteryGameData(resp[0]);
          }
        })
        .catch((err) => {
          toast.error("No data found for the lottery game.");
        }); // Call the lotteryGameData API
    } catch (error) {
      console.error("Error fetching lottery game data:", error);
      toast.error("Error fetching lottery game data.");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="due-days">
        <div className="header-row">
          <div className="setting-title">Lottery Inventory</div>
          {/* <button className="bank-add-button" onClick={handleShow}>
            Return Book
          </button> */}
        </div>
        <div className="mt-4">
          <p className="report-table-title">Lottery Activated Books</p>
        </div>
        <div className="row business-title-header">
          <div className="col-md-6">
            <p className="business-report-title">Scan Now</p>
          </div>
          <div className="col-md-6">
            <p className="business-report-title">Manually</p>
          </div>
        </div>
        <div className="row business-report">
          <div className="col-md-6">
            <div className="data-report">
              <div className="input-lottery-container">
                <input
                  type="date"
                  value={selectedDate} // Controlled input with value tied to state
                  onChange={handleDateChange} // Update state when the date changes
                  className="input-field"
                  style={{
                    width: "160px",
                    marginRight: "10px",
                    height: "44px",
                  }}
                />
                <input
                  type="text"
                  className="input-lottery"
                  placeholder="Scan Number"
                  value={scanNumber}
                  onChange={handleScanNumberChange}
                />
                {/* <button
                  className="btn-activate"
                  style={{ width: "79px", marginLeft: "10px" }}
                  // onClick={handleAddScanNumber}
                >
                  Add
                </button> */}

                <ManageGameModal
                  makeBlank={makeBlank}
                  show={showModalGame}
                  handleClose={handleCloseGame}
                  gameNo={(formData.lottery_game!== "") ? formData.lottery_game : gameNo}
                  bookNo={(formData.book_no!== "") ? formData.book_no : bookNo}
                  // gameNo={formData.lottery_game}
                  fetchGameData={fetchGameData}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="data-report">
              <input
                type="date"
                value={selectedDate} // Controlled input with value tied to state
                onChange={handleDateChange} // Update state when the date changes
                className="input-field"
                style={{
                  width: "160px",
                  marginRight: "10px",
                  height: "44px",
                }}
              />

              {!isGameFound ? (
                <a
                  style={{ color: "white", backgroundColor: '#4545db', padding: "8px", borderRadius: "30px" }}
                  onClick={() => setShowModalGame(true)}
                >
                  Add new game
                </a>
              ) : (
                ""
              )}
            </div>
            <div className="data-report">
              <input
                type="text"
                id="lottery_game"
                className="input-game field"
                placeholder="Game Number"
                value={formData.lottery_game}
                onInput={(e) => {
                  // Allow only numbers and a decimal point with two digits after it
                  e.target.value = e.target.value
                    .replace(/[^0-9.]/g, "") // Remove non-numeric characters except the decimal point

                    .slice(0, 6);
                }}
                onBlur={(e) => {
                  checkScannedNumberManual(e.target.value);
                }}
                onChange={handleInputChange}
              />
            </div>
            <div className="data-report">
              <input
                type="text"
                id="book_no"
                className="input-game field"
                placeholder="Book Number"
                value={(formData.book_no!== "") ? formData.book_no : bookNo}
                onBlur={(e) => setBookNo(e.target.value)}
                onChange={handleInputChange}
                onInput={(e) => {
                  // Allow only numbers and a decimal point with two digits after it
                  e.target.value = e.target.value
                    .replace(/[^0-9.]/g, "") // Remove non-numeric characters except the decimal point

                    .slice(0, 6);
                }}
              />
              {/* <button
                className="btn-activate"
                style={{ width: "79px" }}
                onClick={handleAddGame}
              >
                Add
              </button> */}
            </div>
          </div>

          {isGameFound && lotteryGameData && (
            <div className="lottery-game-details">
              <div className="game-details-row">
                <div className="game-details-cell">
                  <strong>Game No</strong>
                  {gameNo}
                  {/* <input
                    type="text"
                    className="input-game field"
                    value={gameNo}
                    style={{width:"110px"}}
                    onChange={(e) =>
                      setFormData({ ...formData, game_no: gameNo })
                    }
                  /> */}
                </div>
                <div className="game-details-cell">
                  <strong>Game Name</strong>
                  {gameName}
                  {/* <input
                    type="text"
                    className="input-game field"
                    value={gameName}
                    style={{width:"110px"}}
                    onChange={(e) =>
                      setFormData({ ...formData, game_name: gameName})
                    }
                  /> */}
                </div>
                <div className="game-details-cell">
                  <strong>Game Price</strong> {gamePrice}
                </div>
                <div className="game-details-cell">
                  <strong>Total Value</strong> {gamePrice * gameTotalTickets}
                </div>
                <div className="game-details-cell">
                  <strong>Inventory Date</strong>
                  <input
                    type="date"
                    value={selectedDate}
                    className="input-field"
                    style={{
                      width: "160px",
                      marginRight: "10px",
                      height: "44px",
                    }}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        inventory_date: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="game-details-cell">
                  <strong>Book Number</strong>
                  <input
                    type="text"
                    className="input-game field"
                    value={formData.book_no}
                    onInput={(e) => {
                      // Allow only numbers and a decimal point with two digits after it
                      e.target.value = e.target.value
                        .replace(/[^0-9.]/g, "") // Remove non-numeric characters except the decimal point
                        .slice(0, 6);
                    }}
                    onBlur={(e) => setBookNo(e.target.value)}
                    onChange={handleInputChange}
                    id="book_no"
                  />
                </div>
                <div className="game-details-cell">
                  <button
                    className="btn-activate"
                    onClick={handleScanAddGame}
                    style={{ marginLeft: "95px", marginTop: "24px" }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="mt-4">
          <p className="report-table-title">Non Active Books</p>
        </div>
        <div className="lottery-table-container">
          <ReusableModal
            // show={showModal}
            // handleClose={handleClose}
            title={"Return Book"}
            width="auto"
            height="auto"
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
                // handleSave();
              }}
            >
              <div className="form-group d-flex align-items-center mt-0">
                <input
                  type="date"
                  id="date"
                  className="input-field"
                  style={{
                    width: "160px",
                    marginRight: "10px",
                    height: "44px",
                  }}
                />
                <input
                  type="text"
                  id="gameNumber"
                  className="input-field"
                  placeholder="Game Number"
                  // value={vendorName}
                  // onChange={(e) => setVendorName(e.target.value)}
                  style={{
                    width: "270px",
                    marginRight: "10px",
                    height: "44px",
                  }}
                />
                <input
                  type="text"
                  id="bookNumber"
                  className="input-game field"
                  placeholder="Book Number"
                  // value={vendorName}
                  // onChange={(e) => setVendorName(e.target.value)}
                  style={{
                    width: "270px",
                    marginRight: "10px",
                    height: "44px",
                  }}
                />
                <button
                  type="submit"
                  style={{
                    padding: "6px 8px",
                    backgroundColor: "#4545db",
                    color: "white",
                    border: "none",
                    borderRadius: "40px",
                    cursor: "pointer",
                    width: "103px",
                    height: "42px",
                    fontSize: "18px",
                    marginLeft: "20px",
                    // isEditMode
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          </ReusableModal>

          <SettingTable
            data={games}
            columns={columns}
            showAction={true}
            showFooter={true}
            showExport={false}
            showFilter={false}
            onEdit={(handleEdit) => activateBook(handleEdit)} // Open modal for editing user
            onDelete={handleDelete}
          />
        </div>
        <div className="mt-4">
          <p className="report-table-title">Lottery Inventory History</p>
        </div>
        <SettingTable
          data={activatedGames}
          columns={columns2}
          showExport={false}
          showFilter={false}
          // showAction={true}
          showFooter={true}
        // onEdit={(handleEdit) => handleShowUserModal(handleEdit)} // Open modal for editing user
        // onDelete={handleDelete}
        />
      </div>
    </>
  );
}

export default LotteryInventory;
