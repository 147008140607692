/* eslint-disable no-dupe-keys */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../../../utils/secure-route/AuthContext";
import DataTable from "../Payroll/PayrollTable";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import { toast, ToastContainer } from "react-toastify";
import PayrollTableWithButton from "./PayrollTableWithButton";
import Swal from "sweetalert2";

export default function Payroll() {
  const { user } = useContext(AuthContext);
  const storeId = user.store;
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('new');
  const [payrollData, setPayrollData] = useState([]);
  const [salaryHistory, setSalaryHistory] = useState([]);
  const [selectedPayroll, setSelectedPayroll] = useState(null);
  const [bankdata, setBank] = useState([]);

  const [editingPayrollId, setEditingPayrollId] = useState(null);
  const initialFormData = {
    type: "payroll",
    full_name: "",
    contact_no: "",
    email: "",
    date: new Date().toISOString().split("T")[0],
    status: "unpaid",
    pay_method_status: "",
    pay_method: "",
    pay_salary: "",
    bank: '',
    salary: null,
    deductions: null,
    net_salary: null,
    note: "",
    bank: "",
    bank_name: ""

  };
  const initialPaymentData = {
    store: storeId,
    payment_method: "cash",
    salary_amount: "",
    payment_date: new Date().toISOString().split("T")[0],
    bank: "",
    bank_name: "",
    salary_unit: "bi weekly",
    total_hour: "",
    start_date: new Date().toISOString().split("T")[0],
    end_date: new Date().toISOString().split("T")[0],
    note: ""
  };
  const [formData, setFormData] = useState(initialFormData);
  const [paymentData, setPaymentData] = useState(initialPaymentData);
  const { Get, Post, Delete, Patch } = useApi();


  // Map bank_id to bank_name for display purposes



  useEffect(() => {
    fetchSalaryHistory();
    fetchBankData();
  }, []);

  useEffect(() => {
    fetchPayrollData();
  }, []);

  const fetchPayrollData = async () => {
    try {
      const response = await Get('payrollEmployeeData');
      setPayrollData(response);
    } catch (error) {
      console.error('Error fetching payroll data:', error);
      toast.error('Failed to fetch payroll data.');
    }
  };



  const fetchSalaryHistory = async () => {
    try {
      const dataresponse = await Get('payrollSalaryData');
      setSalaryHistory(dataresponse);
    } catch (error) {
      console.error('Error fetching salary history data:', error);
      toast.error('Failed to fetch salary history data.');
    }
  };

  const fetchBankData = async () => {
    try {
      const bankdata = await Get('bank');
      setBank(bankdata);
    } catch (error) {
      console.error('Error fetching bank data:', error);
      toast.error('Failed to fetch bank data.');
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setModalType('new');
    setFormData(initialFormData);
    setPaymentData(initialPaymentData);
    setEditingPayrollId(null);
    setSelectedPayroll(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Restrict non-numeric input for contact_no
    if (name === "contact_no") {
      const numericValue = value.replace(/[^0-9]/g, ""); // Allow only digits
      setFormData((prevData) => ({ ...prevData, [name]: numericValue }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handlePaymentChange = (e) => {
    const { name, value } = e.target;

    if (name === "salary_amount") {
      // Regular expression to allow up to 10 digits, with a decimal point and up to 2 decimal places
      const regex = /^\d{0,10}(\.\d{0,2})?$/;

      // Test if the value matches the regex pattern
      if (regex.test(value)) {
        setPaymentData((prevData) => ({ ...prevData, [name]: value }));
      }
    } else {
      setPaymentData((prevData) => ({ ...prevData, [name]: value }));
    }
  };


  const handleEdit = async (id, type) => {
    if (type === 'payroll') {
      const payroll = payrollData.find(p => p.id === id);
      if (payroll) {
        setFormData({
          ...initialFormData,
          ...payroll
        });
        setEditingPayrollId(id);
        setModalType('edit');
        setShowModal(true);
      }
    } else if (type === 'salaryHistory') {
      try {
        const salaryHistoryResponse = await Get("payrollSalaryData");
        const salary = salaryHistoryResponse.find((s) => s.id === id);
        if (salary) {
          setPaymentData({
            store: storeId,
            salary_amount: salary.total_amount,
            payroll_employee: salary.payroll_employee,
            payment_date: salary.payroll_date,
            salary_unit: salary.salary_unit,
            start_date: salary.start_date,
            end_date: salary.end_date,
            bank: salary.bank || '',
            note: salary.note || '',
            cheque_no: salary.cheque_no || '',
            payment_method: salary.payment_method,
            total_hour: salary.total_hour || ''
          });
          setEditingPayrollId(id);
          setModalType('payment');
          setShowModal(true);
        }
      } catch (error) {
        console.error("Error fetching salary history:", error);
        toast.error("Error fetching salary history: " + error.message);
      }
    }
  };
  const togglePaymentModal = (payroll) => {

    setSelectedPayroll(payroll);
    setModalType('payment');
    setPaymentData(initialPaymentData);
    setShowModal(true);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation checks
    if (!formData.full_name) {
      toast.error("Employee name is required.");
      return;
    }

    const contactRegex = /^[0-9]{10}$/; // Only exactly 10 digits
    if (formData.contact_no && !contactRegex.test(formData.contact_no)) {
      toast.error('Invalid contact number format: must be exactly 10 digits and contain no letters');
      return;
    }

    const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/; // Basic email format validation
    if (formData.email && !emailRegex.test(formData.email)) {
      toast.error('Invalid email format');
      return;
    }

    const updatedFormData = { ...formData, store: storeId };

    try {
      let response;

      // If there's an editing payroll ID, we want to PATCH the payroll employee data
      if (editingPayrollId) {
        response = await Patch("payrollEmployeeData", editingPayrollId, updatedFormData);

        if (response.status === 200) {
          setPayrollData(prevData =>
            prevData.map(payroll =>
              payroll.id === editingPayrollId ? { ...payroll, ...updatedFormData } : payroll
            )
          );
          toast.success("Payroll updated successfully!");
        }
      } else {
        // If there's no editingPayrollId, it's a new payroll record
        response = await Post("payrollEmployeeData", updatedFormData);

        if (response.status === 201) {
          setPayrollData(prevData => [...prevData, response.data]);
          toast.success("Payroll added successfully!");
        } else if (response.data) {
          // Extract specific error messages and display them
          const errorMessages = Object.entries(response.data)
            .map(([field, messages]) => `${messages.join(", ")}`)
            .join("\n");
          toast.error(errorMessages || "An unknown error occurred.");
        } else {
          toast.error("Please Validate Fields");
        }
      }

      closeModal(); // Close the modal after submitting the form
    } catch (error) {
      console.error("Error submitting form", error);
      if (error.response?.data) {
        // Handle API response errors
        const errorMessages = Object.entries(error.response.data)
          .map(([field, messages]) => `${field}: ${messages.join(", ")}`)
          .join("\n");
        toast.error(errorMessages || "An unknown error occurred.");
      } else {
        // Handle generic errors
        toast.error("Error submitting form: " + error.message);
      }
    }
  }






  const handlePaymentSubmit = async (e) => {
    e.preventDefault();

    if (!paymentData.salary_amount) {
      toast.error("Payment amount is required.");
      return;
    }

    if (paymentData.payment_method === "cheque" && !paymentData.bank) {
      toast.error("Please select a bank when paying by cheque.");
      return;
    }

    // Find the bank name based on the selected bank ID
    const selectedBank = bankdata.find((b) => b.id === paymentData.bank);
    const bankName = selectedBank ? selectedBank.name : null;

    try {
      // Create the payload for both new and edit operations
      const payload = {
        store: storeId,
        payroll_employee: selectedPayroll?.id,
        payroll_date: paymentData.payment_date,
        salary_unit: paymentData.salary_unit,
        start_date: paymentData.start_date,
        end_date: paymentData.end_date,
        salary_amount: paymentData.salary_amount,
        total_hour: paymentData.total_hour || 0,
        total_amount: paymentData.salary_amount,
        pay_method: paymentData.payment_method,
        bank: paymentData.payment_method === "cheque" ? paymentData.bank : "",
        bank_name: bankName, // Include bank_name in the payload
        cheque_no:
          paymentData.payment_method === "cheque"
            ? paymentData.cheque_no || ""
            : editingPayrollId
              ? paymentData.cheque_no // Preserve existing cheque_no if editing
              : null,
        note: paymentData.note,
      };

      // For editing existing salary data
      if (editingPayrollId) {
        const updatedResponse = await Patch("payrollSalaryData", editingPayrollId, payload);
        setSalaryHistory((prevHistory) =>
          prevHistory.map((salary) =>
            salary.id === editingPayrollId ? { ...salary, ...updatedResponse.data } : salary
          )
        );
        toast.success("Payment updated successfully!");
      } else {
        // For creating new salary data
        await Post("payrollSalaryData", payload);
        const data = await Get("payrollSalaryData");
        setSalaryHistory(data);
        toast.success("Payment processed successfully!");
      }

      closeModal();
    } catch (error) {
      console.error("Error processing payment:", error);
      toast.error("Error processing payment: " + (error.response?.data || error.message));
    }
  };


  const handleDelete = async (id, type) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    // Check if the user clicked the confirm button
    if (!result.isConfirmed) return; // Exit if the user cancels

    try {
      if (type === "payroll") {
        // Delete payroll record
        await Delete(`payrollEmployeeData`, id);
        // Update payroll data in the frontend
        setPayrollData((prevPayrollData) => prevPayrollData.filter((p) => p.id !== id));

        // Fetch updated salary history data from the backend
        const updatedSalaryHistory = await Get("payrollSalaryData");
        setSalaryHistory(updatedSalaryHistory);

        toast.success("Payroll record deleted successfully!");
      } else if (type === "salaryHistory") {
        // Delete salary history record
        await Delete(`payrollSalaryData`, id);
        // Update salary history data in the frontend
        setSalaryHistory((prevSalaryHistory) =>
          prevSalaryHistory.filter((s) => s.id !== id)
        );

        toast.success("Salary history record deleted successfully!");
      }
    } catch (error) {
      console.error("Error deleting record:", error);
      toast.error("Error deleting record: " + (error.response?.data || error.message));
    }
  };


  return (
    <div className="main-container">
      <div className="p-2">
        <div className="expensecontainer d-flex flex-column pd-2 mb-2">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h3 className="mb-1">Manage Payroll</h3>
            <button
              className="btn btn-primary cus"
              onClick={() => {
                // fetchBankData();
                setModalType('new');
                setShowModal(true);
              }}
            >
              + Add Employee
            </button>
          </div>
          <PayrollTableWithButton
            data={payrollData}
            columns={[
              { header: "Date", field: "date" },
              { header: "Name", field: "full_name" },
              { header: "Phone", field: "contact_no" },
              { header: "Email", field: "email" },
              { header: "Pay Salary", field: "pay_salary" },
            ]}
            showAction={true}
            onEdit={(id) => handleEdit(id, 'payroll')}
            onDelete={(id) => handleDelete(id, 'payroll')}
            togglePaymentModal={togglePaymentModal}
          />
        </div>
        { }
        {showModal && (
          <div className="modal-overlay" onClick={closeModal}>
            <div
              className="modal-container"
              onClick={(e) => e.stopPropagation()}
              style={{ width: modalType === 'payment' ? '800px' : '700px' }}
            >
              <div className="modal-header" style={{ borderBottom: "1px solid lightgray" }}>
                <h3>
                  {modalType === 'new' && "Add Employee"}
                  {modalType === 'edit' && "Edit Employee"}
                  {modalType === 'payment' && `Process Payment for ${selectedPayroll?.full_name || ''}`}
                </h3>

                <svg
                  onClick={closeModal}
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    marginBottom: "20px",
                    marginTop: "5px",
                    cursor: "pointer",
                  }}
                >
                  <path
                    d="M1 1L13 13M13 1L1 13"
                    stroke="black"
                    stroke-linecap="round"
                  />
                </svg>

              </div>
              { }
              {(modalType === 'new' || modalType === 'edit') && (
                <form onSubmit={handleSubmit}>
                  <div className="modal-body">
                    <div className="form-group">
                      <label htmlFor="full_name">Employee Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="full_name"
                        name="full_name"
                        value={formData.full_name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="contact_no">Contact Number</label>
                      <input
                        type="tel"
                        className="form-control"
                        id="contact_no"
                        name="contact_no"
                        value={formData.contact_no}
                        onChange={handleChange}
                        required
                        maxLength={10}
                      />


                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email Address</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary">
                      {modalType === 'edit' ? "Update Employee" : "Add Employee"}
                    </button>
                    <button type="button" className="btn btn-contained" onClick={closeModal}>
                      Close
                    </button>
                  </div>
                </form>
              )}
              { }
              {modalType === 'payment' && (
                <form onSubmit={handlePaymentSubmit}>
                  <div className="modal-body">
                    <div className="form-group">
                      <label htmlFor="salary_amount">Amount</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <input
                          type="text" // Keeping text type for more flexible input control
                          className="form-control"
                          id="salary_amount"
                          name="salary_amount"
                          value={paymentData.salary_amount}
                          onChange={handlePaymentChange}
                          onKeyDown={(e) => {
                            // Prevent negative and positive signs
                            if (e.key === "-" || e.key === "+") {
                              e.preventDefault();
                            }
                          }}
                          required
                          onInput={(e) => {
                            let value = e.target.value;

                            // Remove all characters except numbers and the decimal point
                            value = value.replace(/[^0-9.]/g, '');

                            // Ensure only one decimal point
                            if (value.indexOf('.') !== -1) {
                              const parts = value.split('.');
                              value = parts[0] + '.' + parts[1].substring(0, 2); // Keep only two digits after the decimal
                            }

                            // Update the value on the input element
                            e.target.value = value;

                            // Call the onChange handler to update the state
                            handlePaymentChange(e);
                          }}
                        />
                      </div>
                    </div>


                    <div className="form-row form-group col-md-6">
                      <label htmlFor="salary_unit">Salary Unit</label>
                      <select
                        className="form-control"
                        name="salary_unit"
                        value={paymentData.salary_unit}
                        onChange={handlePaymentChange}
                        required
                      >
                        <option value="hourly">Hourly</option>
                        <option value="weekly">Weekly</option>
                        <option value="bi weekly">Bi Weekly</option>
                        <option value="monthly">Monthly</option>
                      </select>
                    </div>
                    <div className="form-row form-group col-md-6">
                      <label htmlFor="start_date">Start Date</label>
                      <input
                        type="date"
                        className="form-control"
                        name="start_date"
                        value={paymentData.start_date}
                        onChange={handlePaymentChange}
                        required
                      />
                    </div>
                    <div className="form-row form-group col-md-6">
                      <label htmlFor="end_date">End Date</label>
                      <input
                        type="date"
                        className="form-control"
                        name="end_date"
                        value={paymentData.end_date}
                        onChange={handlePaymentChange}
                        required
                      />
                    </div>
                    {/* <ToastContainer /> */}
                    <div className="form-row form-group col-md-6">
                      <label htmlFor="total_hour">Total Hours</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Total Hours"
                        name="total_hour"
                        value={paymentData.total_hour}
                        onChange={handlePaymentChange}
                        onInput={(e) => {
                          // Allow only numbers and a decimal point with two digits after it
                          e.target.value = e.target.value

                            .replace(/[^0-9]/g, '') // Remove non-numeric characters
                            .slice(0, 3); // Limit input length (10 digits + 1 decimal + 2 decimal places)
                        }}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="payment_method">Payment Method</label>
                      <select
                        name="payment_method"
                        id="payment_method"
                        className="form-control"
                        value={paymentData.payment_method}
                        onChange={handlePaymentChange}
                      >
                        <option value="cash">Cash</option>
                        <option value="cheque">Bank Transfer</option>
                      </select>
                    </div>
                    {paymentData.payment_method === "cheque" && (
                      <div className="form-group">
                        <label htmlFor="bank">Bank</label>
                        <select
                          name="bank"
                          id="bank"
                          className="form-control"
                          value={paymentData.bank}
                          onChange={handlePaymentChange}
                        >
                          <option value="">Select Bank</option>
                          {bankdata.map((bank) => (
                            <option key={bank.id} value={bank.id}>
                              {bank.name}
                            </option>
                          ))}
                        </select>
                        <label htmlFor="cheque_no" style={{ marginTop: "20px" }}>Cheque Number</label>
                        <input
                          type="number"
                          className="form-control"
                          id="cheque_no"
                          name="cheque_no"
                          value={paymentData.cheque_no}
                          onChange={handlePaymentChange}
                          required
                        />
                      </div>
                    )}
                    <div className="form-row form-group col-md-6">
                      <label htmlFor="note">Notes</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Notes"
                        name="note"
                        value={paymentData.note}
                        onChange={handlePaymentChange}
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary" style={{}}>
                      Payment
                    </button>
                    <button type="button" className="btn btn-contained" onClick={closeModal}>
                      Close
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        )}
        { }
        <div className="expensecontainer d-flex flex-column pd-2 mb-2">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h3 className="mb-1">Salary History</h3>
          </div>
          <ToastContainer />
          <DataTable
            data={salaryHistory}
            columns={[
              { header: "Date", field: "payroll_date" },
              { header: "Employee", field: "payroll_employee_name" },
              { header: "From Date", field: "start_date" },
              { header: "To Date", field: "end_date" },
              { header: "Payroll Date", field: "payroll_date" },
              { header: "Unit", field: "salary_unit" },
              { header: "Check Number", field: "cheque_no" },
              { header: "Bank", field: "bank_name" },
              { header: "Notes", field: "note" },
              { header: "Amount", field: "total_amount" },
            ]}
            showAction={true}
            showFooter={false}
            onEdit={(id) => handleEdit(id, 'salaryHistory')}
            onDelete={(id) => handleDelete(id, 'salaryHistory')}

            togglePaymentModal={togglePaymentModal}
          />
        </div>
      </div>
    </div>
  );
}