/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import SettingsData from "../../../Schema/SettingsData.json";
import DataTable from "./DataTable";
import "./Settings.css";
import Swal from "sweetalert2";
import ReusableModal from "./ReusableModal";
import { toast, ToastContainer } from "react-toastify";
import AuthContext from "../../../../utils/secure-route/AuthContext";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import CustomSwitch from "./CustomSwitch";
const ManageSettings = () => {
  let { user } = useContext(AuthContext);
  const storeId = user.store;
  const [showModal, setShowModal] = useState(false);
  const [formType, setFormType] = useState("");
  const [dueDaysList, setDueDaysList] = useState([]); // State for due days
  const [editingDueDayId, setEditingDueDayId] = useState(null); // ID of the editing due day
  const [dueDays, setDueDays] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankBranch, setBankBranch] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [isDropdownVisible, setIsDropdownVisible] = useState(true);
  const [numberOfRegisters, setNumberOfRegisters] = useState(0);
  const [numberOfLotteryRegisters, setNumberOfLotteryRegisters] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedRegister, setSelectedRegister] = useState(true);
  const [selectedLotteryRegister, setSelectedLotteryRegister] = useState("");
  const [settingId, setSettingId] = useState(null);
  const [salesTax, setSalesTax] = useState(0);
  const [banks, setBanks] = useState([]);
  const [storeStartDate, setStoreStartDate] = useState();
  const { Get, Post, Put, Delete, Patch } = useApi();
  const [isLoading, setIsLoading] = useState(false);

  const [checkedStates, setCheckedStates] = useState({

  });




  const settingsMap = {
    1: 'lottery_after_end_of_the_day',
    2: 'meal_tax',
    3: 'auto_email_report',
    4: 'default_login_step_report_page',
    5: 'cash_drops',
    6: 'lottery_book_from_laast_number',
    7: 'gas_report_feature',
    8: 'instant_sale_manually',
    12: 'coam_analytics',
    13: 'multiple_business_register',
    14: 'multiple_lottery_register'
  };

  useEffect(() => {
    const storedSettings = JSON.parse(localStorage.getItem('settings') || '{}');
    const initialCheckedStates = {};

    Object.entries(settingsMap).forEach(([questionId, settingKey]) => {
      initialCheckedStates[questionId] =
        storedSettings[settingKey] ?? (questionId === "13" || questionId === "14" ? true : false);
    });

    setCheckedStates(initialCheckedStates);
    setSettingId(storedSettings.id);
  }, []);



  const handleGetMethod = async () => {
    setLoading(true);
    try {
      const response = await Get("settingData");
      if (response && response.length > 0) {
        const {
          no_of_register,
          no_of_register_lottery,
          id,
          sales_tax_percentage,
          store_start_date
        } = response[0];
        setNumberOfRegisters(no_of_register);
        setNumberOfLotteryRegisters(no_of_register_lottery);
        setSettingId(id); // Setting the settingId from the response
        setSalesTax(sales_tax_percentage); // Initial sales tax value
        setSelectedRegister(no_of_register);
        setSelectedLotteryRegister(no_of_register_lottery);
        setStoreStartDate(store_start_date);
      }
    } catch (error) {
      console.error("Error fetching setting data:", error);
      toast.error("Failed to load settings. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handlePatchSalesTax = async (newSalesTaxValue) => {
    if (newSalesTaxValue !== undefined && newSalesTaxValue !== null) {
      try {
        const payload = {
          store: storeId, // Ensure correct store ID is used
          sales_tax_percentage: newSalesTaxValue, // Send updated sales tax
        };

        const response = await Patch(`settingData`, settingId, payload); // Update setting data
        if (response) {
          toast.success("Sales tax updated successfully!");
        }
      } catch (error) {
        console.error("Error updating sales tax:", error);
        toast.error("Failed to update sales tax. Please try again.");
      }
    }
  };
  // Load initial states from local storage
  useEffect(() => {
    const storedStates = JSON.parse(localStorage.getItem('toggleStates')) || {};
    setCheckedStates((prevState) => ({ ...prevState, ...storedStates }));
  }, []);

  // Update local storage whenever checkedStates changes
  useEffect(() => {
    localStorage.setItem('toggleStates', JSON.stringify(checkedStates));
  }, [checkedStates]);



  const handlePatchMethod = async (value) => {
    if (!settingId) {
      toast.error("Setting ID is missing.");
      return;
    }

    try {
      const payload = {
        store: storeId,
        no_of_register: value,
      };
      const response = await Patch(`settingData`, settingId, payload);
      if (response) {
        toast.success("Register updated successfully!");
      }
    } catch (error) {
      console.error(
        "Error updating setting data:",
        error.response || error.message
      );
      toast.error("Failed to update register. Please try again.");
    }
  };


  const handleToggleLotterySetting = async (value) => {
    if (!settingId) {
      toast.error("Setting ID is missing.");
      return;
    }
    try {
      const payload = {
        store: storeId,
        no_of_register_lottery: value, // This field can be customized based on your API requirements
      };
      const response = await Patch(`settingData`, settingId, payload);
      if (response) {
        toast.success("Lottery setting updated successfully!");
      }
    } catch (error) {
      console.error(
        "Error updating lottery setting data:",
        error.response || error.message
      );
      toast.error("Failed to update lottery setting. Please try again.");
    }
  };

  useEffect(() => {
    handleGetMethod();
  }, []);

  const handleIncrementSalesTax = async (event) => {
    const newSalesTaxValue = parseFloat(event.target.value);

    if (!isNaN(newSalesTaxValue)) {
      setSalesTax(newSalesTaxValue); // Update local state

      // Call the API to update the sales tax on the backend
      try {
        await handlePatchSalesTax(newSalesTaxValue); // Ensure this function is defined correctly
      } catch (error) {
        toast.error("Failed to update sales tax");
      }
    } else {
      setSalesTax(""); // Optionally, handle invalid value input
      toast.error("Invalid sales tax value.");
    }
  };

  const handleRegisterChange = (event) => {
    const valueToBeSelectedRegister = event.target.value;
    setSelectedRegister(valueToBeSelectedRegister);
    handlePatchMethod(valueToBeSelectedRegister);
  };

  const handleLotteryRegisterChange = (event) => {
    const valueToBeSelectedLotteryRegister = event.target.value;
    setSelectedLotteryRegister(valueToBeSelectedLotteryRegister);
    handleToggleLotterySetting(valueToBeSelectedLotteryRegister);
  };

  const handleShow = (type) => {
    setFormType(type);
    setShowModal(true);
  };
  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const response = await Get("bank");
        setBanks(response); // Assuming response.data is an array of banks
      } catch (error) {
        console.error("Error fetching bank data", error);
      }
    };
    const fetchDueDays = async () => {
      try {
        const response = await Get("dueDaysData");
        setDueDaysList(response);
      } catch (error) {
        console.error("Error fetching due days", error);
      }
    };

    handlePatchSalesTax();
    fetchBanks();
    fetchDueDays();
  }, []);
  const handleClose = () => {
    setShowModal(false);
    setBankName("");
    setBankBranch("");
    setAccountNumber("");
    setDueDays("");
    setEditingDueDayId(null); // Reset branch on close
  };



  const staticRegisters = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
  ];

  const renderOptions = (selectedValue, numberOfRegisters) => (
    <>
      {/* <option value="">
        {loading ? "Loading..." : numberOfRegisters > 0 ? `${numberOfRegisters}` : `${selectedValue}`}
      </option> */}
      {/* {selectedValue && (
        <option value={selectedValue} className="register-option" style={{ background: "#4545db23", borderRadius: "10px", padding: "15px" }}>
          {`${selectedValue}`}
        </option>
      )} */}
      {staticRegisters.map((register) => (
        <option
          key={register.value}
          value={register.value}
          className="register-option"
        >
          {register.label}
        </option>
      ))}
    </>
  );

  const handleSaveBank = async () => {
    if (bankName && bankBranch && accountNumber && storeId) {
      try {
        const newBank = {
          name: bankName,
          branch: bankBranch,
          account_no: accountNumber,
          store: storeId,
        };
        // Make the API call to add the bank
        const response = await Post("bank", newBank);
        setBanks((prevBanks) => [...prevBanks, response.data]);
        toast.success("Bank added successfully!");
        handleClose(); // Close the form/modal
      } catch (error) {
        console.error("Error adding bank", error);
        toast.error("Error adding bank. Please try again.");
      }
    } else {
      toast.warn("Please fill in all required fields.");
    }
  };
  const handleDeleteBank = async (bankId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    // Check if the user clicked the confirm button
    if (!result.isConfirmed) return; // Exit if the user cancels

    try {
      await Delete("bank", bankId);
      setBanks((prevBanks) => prevBanks.filter((bank) => bank.id !== bankId));
      toast.success("Bank deleted successfully!");
    } catch (error) {
      console.error("Error deleting bank:", error);
      toast.error("Error deleting bank. Please try again.");
    }
  };
  const handleSaveDueDays = async () => {
    if (dueDays) {
      const newDueDay = { days: dueDays, store: storeId };
      try {
        if (editingDueDayId) {
          // Update existing due day
          await Put("dueDaysData", editingDueDayId, newDueDay);
          setDueDaysList((prevList) =>
            prevList.map((day) =>
              day.id === editingDueDayId
                ? { ...day, days: dueDays, store: storeId }
                : day
            )
          );
          toast.success("Due day updated successfully!");
        } else {
          const response = await Post("dueDaysData", newDueDay);

          // Ensure the response is correctly handled
          if (response && response.data) {
            const createdDueDay = response.data; // Get the actual response data
            setDueDaysList((prevList) => [...prevList, createdDueDay]);
            toast.success("Due day added successfully!");
          } else {
            toast.error("Failed to retrieve the created due day data.");
          }
        }
        handleClose();
      } catch (error) {
        console.error("Error adding or updating due day:", error);
        toast.error("Error saving due day. Please try again.");
      }
    } else {
      toast.warn("Due days value is required.");
    }
  };

  const handleEditDueDay = (day) => {
    setDueDays(day.days);
    setEditingDueDayId(day.id);
    handleShow("dueDays");
  };
  const handleDeleteDueDay = async (dayId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    // Check if the user clicked the confirm button
    if (!result.isConfirmed) return; // Exit if the user cancels
    try {
      await Delete("dueDaysData", dayId);
      setDueDaysList((prevList) => prevList.filter((day) => day.id !== dayId));
      toast.success("Due day deleted successfully!");
    } catch (error) {
      console.error("Error deleting due day", error);
      toast.error("Error deleting due day. Please try again.");
    }
  };


  const handleSwitchChange = async (id) => {
    setIsLoading(true);
    try {
      // Toggle state for all settings
      const newState = !checkedStates[id];

      setCheckedStates(prev => ({
        ...prev,
        [id]: newState
      }));

      // Update localStorage
      const currentSettings = JSON.parse(localStorage.getItem('settings') || '{}');
      const settingKey = settingsMap[id];

      const updatedSettings = {
        ...currentSettings,
        [settingKey]: newState
      };

      localStorage.setItem('settings', JSON.stringify(updatedSettings));

      // Prepare API payload
      const payload = { [settingKey]: newState };

      // Call API
      const response = await Patch('settingData', settingId, payload);

      if (response.status === 200 || response.status === 204) {
        toast.success(`"${settingKey}" has been ${newState ? 'enabled' : 'disabled'} successfully.`);
      } else {
        throw new Error('Unexpected response from server');
      }

    } catch (error) {
      console.error('Error updating setting:', error);

      // Revert on error
      setCheckedStates(prev => ({
        ...prev,
        [id]: !prev[id]
      }));

      toast.error('Failed to update setting. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };



  return (
    <>
      <div className="setting-container">
        <ToastContainer />
        <p className="setting-title">Manage Settings</p>
        <div className="row setting-manage">
  {/* Render settings excluding 13 & 14 */}
  {SettingsData.filter((setting) => setting.id !== 13 && setting.id !== 14).map((setting) => {
    const isExpanded = checkedStates[setting.id];

    return (
      <div className="col-md-6" key={setting.id}>
        <div className="card">
          <div className={`card-body ${isExpanded ? `auto-height-${setting.id}` : ''}`}>
            <div className="question-switch-container">
              <p className="question-text">{setting.question}</p>
              <CustomSwitch
                setting={setting}
                isActive={isExpanded}
                handleSwitchChange={() => handleSwitchChange(setting.id)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  })}

  {/* Keep settings 13 & 14 in the same row but displayed separately */}
  <div className="col-md-12">
    <div className="row">
      {[13, 14].map((id) => {
        const setting = SettingsData.find((s) => s.id === id);
        const isExpanded = checkedStates[id];

        return (
          <div className="col-md-6" key={id}>
            <div className="card">
            <div className={`card-body ${isExpanded ? `auto-height-${id}` : ''}`}>

                <div className="question-switch-container">
                  <p className="question-text">{setting.question}</p>
                  <CustomSwitch
                    setting={setting}
                    isActive={isExpanded}
                    handleSwitchChange={() => handleSwitchChange(id)}
                  />
                </div>

                {isExpanded && (
                  <div className="card01">
                    {id === 13 && (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label htmlFor={`register-${id}`}>Select Number of Business Registers?</label>
                        <select
                          id={`register-${id}`}
                          className="form-select"
                          value={selectedRegister}
                          onChange={handleRegisterChange}
                        >
                          {renderOptions(selectedRegister, numberOfRegisters)}
                        </select>
                      </div>
                    )}
                    {id === 14 && (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label htmlFor={`lottery-register-${id}`}>Select Number of Lottery Registers?</label>
                        <select
                          id={`lottery-register-${id}`}
                          className="form-select"
                          value={selectedLotteryRegister}
                          onChange={handleLotteryRegisterChange}
                        >
                          {renderOptions(selectedLotteryRegister, numberOfLotteryRegisters)}
                        </select>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  </div>




           

          <div className="row mt-3">
            <div className="col-md-3 d-flex align-items-center">
              <label htmlFor="store_start_date" className="me-2">Store Start Date:</label> {/* Add margin to the right of the label */}
              <input
                type="date"
                id="store_start_date"
                value={storeStartDate}
                onChange={(e) => setStoreStartDate(e.target.value)}
                className="form-control"
                style={{
                  flex: 1, // Allow input to take the remaining space
                  backgroundColor: '#f6f6f6'
                }}
              />
            </div>

            <div className="col-md-4 d-flex align-items-center">
              <label htmlFor="sales_tax_percentage" className="me-2">Sales Tax Percentage:</label> {/* Add margin to the right of the label */}
              <input
                type="number"
                id="sales_tax_percentage"
                value={salesTax}
                onBlur={handleIncrementSalesTax}
                onChange={(e) => setSalesTax(e.target.value)}
                placeholder="Enter percentage"
                className="form-control"
                style={{
                  flex: 1, // Allow input to take the remaining space
                  backgroundColor: '#f6f6f6'
                }}
              />
            </div>
          </div>


        </div>
      </div>
      <div className="bank-container">
        <div className="header-row">
          <div className="setting-title">Manage Bank</div>
          <button
            className="bank-add-button"
            onClick={() => handleShow("bank")}
          >
            + Add Bank
          </button>
        </div>
        <div className="second-container">
          <div className="bank-cards">
            {banks.map((bank, index) => (
              <div className="bank-card" key={index}>
                <div className="bank-details">
                  <div className="bank-name-group">
                    <div className="bank-name">Bank Name</div>
                    <div className="bank-short-name">{bank.name}</div>
                  </div>
                  <div className="bank-name-group">
                    <div className="bank-name">Bank Short Name</div>
                    <div className="bank-short-name">{bank.branch}</div>
                  </div>
                  <svg
                    onClick={() => handleDeleteBank(bank.id)}
                    className="del-icon"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.99996 16.0904C4.99996 17.0081 5.74996 17.7589 6.66663 17.7589H13.3333C14.25 17.7589 15 17.0081 15 16.0904V7.74777C15 6.83008 14.25 6.07925 13.3333 6.07925H6.66663C5.74996 6.07925 4.99996 6.83008 4.99996 7.74777V16.0904ZM15 3.57645H12.9166L12.325 2.98412C12.175 2.83396 11.9583 2.74219 11.7416 2.74219H8.25829C8.04163 2.74219 7.82496 2.83396 7.67496 2.98412L7.08329 3.57645H4.99996C4.54163 3.57645 4.16663 3.95187 4.16663 4.41072C4.16663 4.86956 4.54163 5.24498 4.99996 5.24498H15C15.4583 5.24498 15.8333 4.86956 15.8333 4.41072C15.8333 3.95187 15.4583 3.57645 15 3.57645Z"
                      fill="#002300"
                    />
                  </svg>
                </div>
                {/* <button className="card-button default">
                  Mark Default Bank
                </button>
                <button className="card-button atm">
                  Mark Default ATM Bank
                </button>
                <button className="card-button ltry">
                  Mark Default Lottery Bank
                </button>
                <button className="card-button credit">
                  Mark Default Credit Card Bank
                </button> */}
              </div>
            ))}
          </div>
          <ReusableModal
            show={showModal && formType === "bank"}
            handleClose={handleClose}
            title="Add Bank"
            width="1054px"
            height="auto"
          >
            <form>
              <div className="form-group d-flex align-items-center mt-1">
                <input
                  type="text"
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                  className="input-field"
                  placeholder="Bank Name"
                  style={{ width: "483px", marginRight: "10px" }}
                />
                <input
                  type="text"
                  value={bankBranch}
                  onChange={(e) => setBankBranch(e.target.value)}
                  className="input-field"
                  placeholder="Bank Short Name"
                  style={{ width: "483px", marginRight: "10px" }}
                />
              </div>
              <div className="form-group d-flex align-items-center mt-1">
                <input
                  type="text"
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                  className="input-field"
                  placeholder="Account Number"
                  maxLength={17}
                  style={{ width: "483px", marginRight: "10px" }}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  onClick={handleSaveBank}
                  type="button"
                  style={{
                    marginRight: "40px",
                    padding: "4px 8px",
                    backgroundColor: "#4545db",
                    color: "white",
                    border: "none",
                    borderRadius: "40px",
                    cursor: "pointer",
                    width: "124px",
                    height: "42px",
                    fontSize: "18px",
                  }}
                >
                  Add Bank
                </button>
              </div>
            </form>
          </ReusableModal>
        </div>
      </div>
      <div className="due-days">
        <div className="header-row">
          <div className="setting-title">Due Days</div>
          <button
            className="bank-add-button"
            onClick={() => handleShow("dueDays")}
          >
            + Add New
          </button>
        </div>
        <div className="second-container">
          {dueDaysList.map((day, index) => (
            <div key={index} className="due-card">
              <div className="due-details">
                <div className="bank-name-group">
                  <div className="bank-name">Due Days</div>
                </div>
                <div className="due-icon">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => handleEditDueDay(day)}
                  >
                    <path
                      d="M15.0083 3.86875C15.3333 3.54375 15.3333 3.00208 15.0083 2.69375L13.0583 0.74375C12.75 0.41875 12.2083 0.41875 11.8833 0.74375L10.35 2.26875L13.475 5.39375M0.25 12.3771V15.5021H3.375L12.5917 6.27708L9.46667 3.15208L0.25 12.3771Z"
                      fill="#002300"
                    />
                  </svg>
                  <svg
                    width="13"
                    height="16"
                    viewBox="0 0 13 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => handleDeleteDueDay(day.id)}
                  >
                    <path
                      d="M1.74996 13.8333C1.74996 14.75 2.49996 15.5 3.41663 15.5H10.0833C11 15.5 11.75 14.75 11.75 13.8333V5.5C11.75 4.58333 11 3.83333 10.0833 3.83333H3.41663C2.49996 3.83333 1.74996 4.58333 1.74996 5.5V13.8333ZM11.75 1.33333H9.66663L9.07496 0.741667C8.92496 0.591667 8.7083 0.5 8.49163 0.5H5.00829C4.79163 0.5 4.57496 0.591667 4.42496 0.741667L3.83329 1.33333H1.74996C1.29163 1.33333 0.916626 1.70833 0.916626 2.16667C0.916626 2.625 1.29163 3 1.74996 3H11.75C12.2083 3 12.5833 2.625 12.5833 2.16667C12.5833 1.70833 12.2083 1.33333 11.75 1.33333Z"
                      fill="#002300"
                    />
                  </svg>
                </div>
              </div>
              <div className="input-fields">
                <input
                  className="input-day"
                  value={day.days}
                  readOnly
                  style={{ paddingLeft: "10px" }}
                />
              </div>
              {/* <button className="card-button day">Mark as Default</button> */}
            </div>
          ))}
        </div>
        <ReusableModal
          show={showModal && formType === "dueDays"}
          handleClose={handleClose}
          title="Add Due Days"
          width="683px"
          height="172px"
        >
          <form>
            <div className="form-group d-flex align-items-center">
              <input
                type="number"
                value={dueDays}
                onChange={(e) => setDueDays(e.target.value)}
                className="input-field"
                placeholder="Due Days"
                style={{ width: "483px", marginRight: "10px" }}
              />
              <button
                onClick={handleSaveDueDays}
                type="button"
                style={{
                  marginRight: "40px",
                  padding: "4px 8px",
                  backgroundColor: "#4545db",
                  color: "white",
                  border: "none",
                  borderRadius: "40px",
                  cursor: "pointer",
                  width: "124px",
                  height: "42px",
                  fontSize: "18px",
                }}
              >
                {editingDueDayId ? "Update" : "Save"}
              </button>
            </div>
          </form>
        </ReusableModal>
      </div>
    </>
  );
};
export default ManageSettings;
