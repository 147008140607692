import { createContext, useEffect, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify"; // Import ToastContainer
import { jwtDecode } from "jwt-decode"; // Ensure jwt-decode is imported correctly
import global from "../../config/Global.json";
import { useNavigate } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css"; // Don't forget to import the Toastify styles

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  let getAPI = (API_NAME) => {
    return global.api.host + global.api[API_NAME];
  }

  const [authToken, setAuthToken] = useState(() =>
    localStorage.getItem("authToken") ? JSON.parse(localStorage.getItem("authToken")) : null
  );

  const [user, setUser] = useState(() =>
    localStorage.getItem("authToken") ? jwtDecode(localStorage.getItem("authToken")) : {}
  );

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Use useNavigate inside the component

  const loginUser = (e) => {
    e.preventDefault();

    axios.post(getAPI("token"), {
        username: e.target.username.value,
        password: e.target.password.value,
      })
      .then((response) => {
        if (global.checkSuperUser !== undefined && global.checkSuperUser === true) {
          checkSuperUser(response.data.access);
        }
        console.log(response.data)

        setAuthToken(response.data);
        setUser(jwtDecode(response.data.access));
        localStorage.setItem("authToken", JSON.stringify(response.data));
        
        const settings = JSON.parse(localStorage.getItem("settings") || "{}");
        
        if (settings.default_login_step_report_page) {
          // Mark first login after setting flag
          localStorage.setItem('firstLoginAfterSettingFlag', 'true');
        }

        // Show success toast
        toast.success("Login successful! Welcome back!");
      })
      .catch((error) => {
        if (error.response && error.response.data.detail) {
          if (error.response.data.detail === "No active account found with the given credentials") {
            toast.error("Username or password incorrect. Please try again.");
          } else {
            toast.error("An error occurred. Please try again.");
          }
        } else {
          toast.error("An error occurred. Please try again.");
        }
        console.error(error);
      });
  };

  const checkSuperUser = async (token) => {
    try {
      const response = await axios.get(getAPI("isSuperUser"), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.is_superuser) {
        toast.success("Welcome, Admin");
      } else {
        logOutUser("User is not super user");
      }
    } catch (error) {
      toast.error("Error checking user permissions");
      console.error(error);
    }
  };

  const logOutUser = (message) => {
    setAuthToken(null);
    setUser({});
    localStorage.removeItem("authToken");

    if (message) {
      toast.error(message);
    }

    // Clear routes and navigate to login
    navigate("/login", { replace: true });  // `replace: true` replaces the current entry in the history stack
  };

  useEffect(() => {
    const checkSuperUserRefresh = async (token) => {
      try {
        const response = await axios.get(getAPI("isSuperUser"), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.data.is_superuser) {
          logOutUser("User is not super user");
        }
      } catch (error) {
        toast.error("Error checking user permissions");
        console.error(error);
      }
    };

    const refreshToken = () => {
      if (authToken?.refresh) {
        axios
          .post(getAPI("refreshToken"), { refresh: `${authToken.refresh}` })
          .then((response) => {
            if (global.checkSuperUser !== undefined && global.checkSuperUser === true) {
              checkSuperUserRefresh(response.data.access);
            }

            setAuthToken(response.data);
            setUser(jwtDecode(response.data.access));
            localStorage.setItem("authToken", JSON.stringify(response.data));
          })
          .catch(logOutUser); // Log out if there's an error
      } else {
        logOutUser();
      }

      if (loading) {
        setLoading(false);
      }
    };

    if (loading) {
      refreshToken();
    }

    const fourMinutes = 1000 * 60 * 4;
    const interval = setInterval(() => {
      if (authToken) {
        refreshToken();
      }
    }, fourMinutes);
    return () => clearInterval(interval);
  }, [authToken, loading]);

  const contextData = {
    user,
    loginUser,
    logOutUser,
    authToken,
  };

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
      <ToastContainer /> {/* Add ToastContainer to render toasts */}
    </AuthContext.Provider>
  );
};
