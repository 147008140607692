import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import loaderGif from "../../../../assets/img/loader.gif";
import { toast } from "react-toastify";
import LotteryHistoryModal from "./LotteryHistoryModal";
import ConfirmationModal from "./ConfirmationModal";

export default function ReportDateSelection() {
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState(
    new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
  );
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(""); // 'previous' or 'today'
  const [shift, setShift] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [historyReport, setHistoryReport] = useState(false);
  const { Post, Get } = useApi();
  const dateInputRef = useRef(null);
  const [activeShift, setActiveShift] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [reportHistory, setReportHistory] = useState(false);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  useEffect(() => {
    checkActiveShift();
    if (dateInputRef.current) {
      dateInputRef.current.focus();
    }
  }, []);

  const checkActiveShift = async () => {
    try {
      const response = await Get("isShiftActive");
      if (response.message === "You already have an active shift") {
        setActiveShift(response.data);
        setShift(response.new_shift_title);
        navigate("/reports");
      } else {
        setActiveShift(response.data);
        setShift(response.new_shift_title);
        setLoading(false);
      }

      const historyReport = response.history_report;
      if (historyReport === "true") {
        setButtonDisabled(true);
      } else {
        setButtonDisabled(false);
      }
    } catch (error) {
      console.error("Error checking active shift:", error);
      setLoading(false);
    }
  };

  const isDateInPast = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const selectedDate = new Date(date);
    selectedDate.setHours(0, 0, 0, 0);
    return selectedDate < today;
  };

  const isToday = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const selectedDate = new Date(date);
    selectedDate.setHours(0, 0, 0, 0);
    return selectedDate.getTime() === today.getTime();
  };

  const handleDateChange = (event) => {
    const date = event.target.value;
    setSelectedDate(date);

    if (isDateInPast(date)) {
      setModalType("previous");
      setShowModal(true);
    } else if (isToday(date)) {
      setModalType("today");
      setShowModal(true);
    } else {
      setShowModal(false);
      setModalType("");
    }
  };

  const handleConfirmPastDate = () => {
    Post("getShiftDataWithRole", { date: selectedDate })
      .then((response) => {
        if (response?.error) {
          toast.error(response.error.message || "Something went wrong!");
        } else {
          navigate("/reports", { state: { selectedDate } });
        }
      })
      .catch((error) => {
        toast.error(
          error.response.data.message || "An unexpected error occurred"
        );
      })
      .finally(() => {
        setShowModal(false);
      });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalType("");
    setErrorMessage("");
    if (dateInputRef.current) {
      dateInputRef.current.value = "";
    }
    setSelectedDate("");
  };

  const handleSubmit = async () => {
    try {
      const response = await Post("createShiftBusinessReportAndRegisters");
      if (response.status === 200) {
        toast.success("Shift report created successfully!");
      } else {
        setErrorMessage("Failed to create shift report. Please try again.");
      }
    } catch (error) {
      console.error("Error creating shift report:", error);
      setErrorMessage(
        "Error occurred while creating the report. Please try again."
      );
    }
  };

  const handleConfirm = async () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const selectedDateObj = new Date(selectedDate);
    selectedDateObj.setHours(0, 0, 0, 0);

    // Check if selected date is not today
    if (selectedDateObj.getTime() !== today.getTime()) {
      return;
    }

    if (!activeShift) {
      await handleSubmit();
    }
    navigate("/reports", { state: { selectedDate } });
  };

  const handleShiftChange = (event) => {
    const selectedShift = event.target.value;
    setShift(selectedShift);
    setSelectedDate(new Date().toISOString().split("T")[0]);
  };

  const createHistoricalReport = async () => {
    try {
      const response = await Post("createHistoricalReport");
      setData(response);
      setOpen(true);
      resetData();
      setButtonDisabled(true);
      setReportHistory(true);
      toast.success("Historical report created successfully!");
    } catch (error) {
      console.error("Error calling API:", error);
      toast.error(
        "Error occurred while creating the report. Please try again."
      );
    }
  };

  const handleButtonClick = () => {
    if (reportHistory) {
      toast.error("A shift for history lottery report already exists");
    } else {
      createHistoricalReport();
    }
  };

  const handleCloseModals = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmClose = async () => {
    setConfirmDialogOpen(false);
    setOpen(false);
    resetData();
  };

  const handleCancelClose = () => {
    setConfirmDialogOpen(false);
  };

  const resetData = () => {
    setData({});
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "600px" }}
      >
        <img
          src={loaderGif}
          alt="Loading..."
          style={{ width: "100px", height: "100px" }}
        />
      </div>
    );
  }

  return (
    <div className="d-flex p-2">
      <div className="expensecontainer d-flex flex-column pd-2">
        {!(buttonDisabled || historyReport === "true") && (
          <button
            className="add-button"
            style={{
              backgroundColor: "#4545db",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px 10px",
              width: "190px",
              height: "40px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={handleButtonClick}
          >
            +Add Lottery History
          </button>
        )}

        <LotteryHistoryModal
          open={open}
          onClose={handleCloseModals}
          data={data}
        />

        <ConfirmationModal
          open={confirmDialogOpen}
          onConfirm={handleConfirmClose}
          onCancel={handleCancelClose}
          title="Are you sure you want to close the lottery report?"
          description="If you close, you won't be able to create the lottery history report a second time."
        />

        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "700px" }}
        >
          <div className="d-flex flex-column p-2 col-md-5">
            <div className="col-md-12 d-flex align-items-center justify-content-center">
              <label htmlFor="dateInput" className="form-label mb-0 me-2">
                Select Shift Date:
              </label>
              <input
                id="dateInput"
                type="date"
                className="form-control-borderless"
                value={selectedDate}
                onChange={handleDateChange}
                ref={dateInputRef}
                style={{ width: "auto", flex: "1", marginBottom: "10px" }}
              />
            </div>

            <div className="row mb-3">
              <div className="col-md-6 d-flex align-items-center justify-content-center">
                <label htmlFor="timeInput" className="form-label mb-0 me-2">
                  Select Time:
                </label>
                <input
                disabled={true}
                  id="timeInput"
                  type="time"
                  className="form-control-borderless"
                  value={selectedTime.slice(0, 5)}
                  onChange={(e) => setSelectedTime(e.target.value)}
                  style={{ width: "auto", flex: "1" }}
                />
              </div>

              <div className="col-md-6 d-flex align-items-center justify-content-center">
                <label htmlFor="shiftSelect" className="form-label mb-0 me-2">
                  Select Shift:
                </label>
                <select
                  id="shiftSelect"
                  className="form-control-borderless"
                  value={shift}
                  onChange={handleShiftChange}
                  style={{ width: "auto", flex: "1" }}
                >
                  <option value={shift}>{shift}</option>
                </select>
              </div>
            </div>

            {showModal && <div className="modal-backdrop fade show"></div>}

            {/* Previous Date Modal */}
            {modalType === "previous" && (
              <div
                className={`modal ${showModal ? "show" : ""}`}
                style={{ display: showModal ? "block" : "none" }}
                tabIndex="-1"
                role="dialog"
                aria-hidden={!showModal}
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Previous Date Report</h5>
                    </div>
                    <div className="modal-body">
                      You have selected a previous date. Are you sure you want
                      to open this report?
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-contained"
                        onClick={handleCloseModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleConfirmPastDate}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Today's Date Modal */}
            {modalType === "today" && (
              <div
                className={`modal ${showModal ? "show" : ""}`}
                style={{ display: showModal ? "block" : "none" }}
                tabIndex="-1"
                role="dialog"
                aria-hidden={!showModal}
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Confirm Today's Shift</h5>
                    </div>
                    <div className="modal-body">
                      Do you want to start{" "}
                      <span
                        style={{
                          border: ".5px solid #000",
                          padding: "3px 5px",
                          borderRadius: "3px",
                          backgroundColor: "#4545DB",
                          color: "#f4f4f4",
                        }}
                      >
                        &nbsp;{shift}&nbsp;
                      </span>
                      &nbsp; today at {selectedTime}?
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-contained"
                        onClick={handleCloseModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleConfirm}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
