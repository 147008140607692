import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import useApi from "../../../../utils/api-manager/Helper/useApi";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { ErrorSvg } from '../../../../utils/api-manager/Forms/SvgIcons';

const stripePromise = loadStripe(
  "pk_test_51Q3jDRC4aAVHkmzjl5CIJRu4U8foi32rigkEJ6gFhEzTDrZvfwpZEwrpYxuugyXRLzAia2ZKoMxu58H6hVDuuko900kINa7O8c"
);

// Styles for different types of plans
const planStyles = {
  "Free Plan": {
    border: "1px solid #00B2FF",
    backgroundColor: '#00B2FF10',
    button: {
      height: "40px",
      borderRadius: "50px",
      backgroundColor: '#000000',
      '&:hover': {
        backgroundColor: '#1F2937',
      },
    },
  },
  "Premium": {
    border: "1px solid #00B2FF",
    backgroundColor: '#00B2FF10',
    button: {
      height: "40px",
      borderRadius: "50px",
      backgroundColor: '#00B2FF',
      '&:hover': {
        backgroundColor: '#00B2FF',
      },
    },
  },
  "Enterprise": {
    border: "1px solid #00B2FF",
    backgroundColor: '#00B2FF10',
    button: {
      height: "40px",
      border: "1px solid #00B2FF",
      borderRadius: "50px",
      backgroundColor: '#00B2FF',
      '&:hover': {
        backgroundColor: '#00B2FF',
      },
    },
  },
  active: {
    border: "2px solid #22C55E", // Green border for active plan
    backgroundColor: '#DCF8E8', // Light green background for active plan
    button: {
      backgroundColor: '#22C55E', // Green button for active plan
      '&:hover': {
        backgroundColor: '#16A34A', // Darker green when hovered
      },
    },
  },
};

const styles = {
  modal: {
    position: 'fixed',
    inset: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9999,
  },
  modalContent: {
    backgroundColor: 'white',
    padding: '2rem',
    borderRadius: '1rem',
    maxWidth: '72rem',
    width: '100%',
    margin: '0 1rem',
    position: 'relative',
    maxHeight: '80vh', 
    overflowY: 'scroll', 
    scrollbarWidth: 'none', 
    msOverflowStyle: 'none', 
  },
  

  '&::-webkit-scrollbar': {
    display: 'none',
  },
  
  
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1.5rem',
  },
  plansContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1.5rem',
    justifyContent: 'center',
  },
  planCard: {
    flex: '1',
    minWidth: '280px',
    maxWidth: '320px',
    height: '500px',
    display: 'flex',
    flexDirection: 'column',
    padding: '1.5rem',
    border: '1px solid #E5E7EB',
    borderRadius: '1.5rem',
   
  },
  
 
  
  planHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    
  },
  planTitle: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: '#1F2937',
  },
  price: {
    fontSize: '1.125rem',
    fontWeight: 'bold',
    color: '#1F2937',
    position: 'relative',
  },
  dollarSign: {
    position: 'absolute',
    top: '-0.5rem',
    right: '-0.75rem',
    fontSize: '1rem',
  },
  featuresList: {
    flex: 1,
    paddingTop: '1rem',
    paddingBottom: '1rem',
    overflowY: 'auto',
     overflowY: 'hidden', // Enables vertical scrolling
    scrollbarWidth: 'none', // Hides scrollbar in Firefox
    msOverflowStyle: 'none', // Hides scrollbar in IE/Edge
  },
   // For WebKit browsers (Chrome, Safari)
   '&::-webkit-scrollbar': {
    display: 'none',
  },
  featuresTitle: {
    fontWeight: 'bold',
    fontSize: '1rem',
    marginBottom: '1rem',
  },
  featureItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem',
    marginBottom: '0.75rem',
  },
  checkmark: {
    width: '1.25rem',
    height: '1.25rem',
    backgroundColor: '#22C55E',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '0.875rem',
  },
  button: {
    width: '100%',
    padding: '0.75rem 2rem',
    borderRadius: '50px',
    fontWeight: 'bold',
    border: '1px solid #282E26',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
    marginTop: 'auto',
  },
  checkoutModal: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '2rem',
    borderRadius: '1rem',
    width: '50%',
    maxHeight: '90vh',
    overflowY: 'auto',
    zIndex: 10000,
  }
};

const SubscriptionModal = ({ show, handleClose, onPlanSelect }) => {
  const [plans, setPlans] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [showCheckout, setShowCheckout] = useState(false);
  const [selectedPlanData, setSelectedPlanData] = useState(null);
  const { Get, getAPI } = useApi();

  // Fetch subscription plans
  useEffect(() => {
    fetchPlans();
    getSubscriptionData();
  }, []);

  const fetchPlans = async () => {
    try {
      const response = await Get('subscriptionsData');
      setPlans(response.results);
    } catch (error) {
      console.error('Error fetching subscription data:', error);
      toast.error('Failed to fetch subscription plans');
    }
  };

  const getSubscriptionData = async () => {
    try {
      const response = await Get("getSubscriptionsByStore");
      setSubscriptions(response);
    } catch (error) {
      console.error('Error fetching active subscriptions:', error);
      toast.error('Failed to fetch subscription status');
    }
  };

  const handlePlanSelection = async (planId) => {
    try {
      let api = `${getAPI("subscriptionsData")}${planId}`;
      const planDetails = await Get(api);
      setSelectedPlanData(planDetails);
      setShowCheckout(true);
      // handleClose(); 

    } catch (error) {
      console.error('Error selecting plan:', error);
      toast.error('Failed to select plan');
    }
  };

  const handleCheckoutClose = () => {
    setShowCheckout(false);
      handleClose(); 
    
  };


  if (!show) return null;

  return (
    <div style={styles.modal}>
    <div style={styles.modalContent}>
      <div style={styles.header}>
        <h2 style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Choose Your Plan</h2>
        <button
          onClick={handleClose}
          style={{
            background: 'none',
            border: 'none',
            fontSize: '1.5rem',
            cursor: 'pointer',
          }}
        >
        </button>
      </div>
  
      {plans.length > 0 ? (
        <div style={styles.plansContainer}>
          {plans.map((plan, index) => {
            const isActive = subscriptions.some(sub => sub.title === plan.title && sub.is_active);
            const planStyle = planStyles[plan.title] || {};
            const activeStyle = isActive ? planStyles.active : {};
  
            return (
              <div
                key={index}
                style={{
                  ...styles.planCard,
                  border: isActive ? '2px solid #22C55E' : '1px solid #e5e5e5',
                  ...planStyle,
                  ...activeStyle,
                }}
              >
                <div style={styles.planHeader}>
                  <p style={styles.planTitle}>{plan.title}</p>
                  <p style={styles.price}>
                    {parseFloat(plan.amount).toFixed(2)}
                    <span style={styles.dollarSign}>$</span>
                  </p>
                </div>
  
                <div style={styles.featuresList}>
                  <p style={styles.featuresTitle}>Features</p>
                  {plan.inc_features_titles.map((feature, idx) => (
                    <div key={idx} style={styles.featureItem}>
                      <div style={styles.checkmark}>✓</div>
                      <span style={{ color: '#4B5563' }}>{feature}</span>
                    </div>
                  ))}
                </div>
  
                <button
                  onClick={() => handlePlanSelection(plan.id)}
                  disabled={isActive}
                  style={{
                    ...styles.button,
                    backgroundColor: isActive ? '#22C55E' : '#282E26',
                    color: '#fff',
                    ...planStyle.button,
                    ...activeStyle.button,
                  }}
                >
                  {isActive ? 'Active Plan' : 'Get Started'}
                </button>
              </div>
            );
          })}
        </div>
      ) : (
        <div style={styles.noPlansMessage}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
          <ErrorSvg />
          <p
            style={{
              fontSize: '1.5rem',
              color: '#374151',
              textAlign: 'center',
              margin: 0,
              lineHeight: 1.6,
            }}
          >
            No plans are currently available for your account. 
            <br />
            Please contact the administrator for further assistance.
          </p>
        </div>
      </div>
      
      )}
    </div>
  
    {/* Checkout Modal */}
    {showCheckout && selectedPlanData && (
      <div style={styles.checkoutModal}>
        <button
          onClick={handleCheckoutClose}
          style={{
            position: 'absolute',
            right: '1rem',
            top: '1rem',
            background: 'none',
            border: 'none',
            fontSize: '1.5rem',
            cursor: 'pointer',
          }}
        >
          ×
        </button>
        <Elements stripe={stripePromise}>
          <CheckoutForm
            modelData={selectedPlanData}
            handleClose={handleCheckoutClose}
          />
        </Elements>
      </div>
    )}
  </div>
  
  );
};

export default SubscriptionModal;