/* eslint-disable no-unused-vars */
import ConfirmPassword from "../components/common/ConfirmPassword";
import ForgotPassword from "../components/common/ForgotPassword";
import SignUp from "../components/common/SignUp";
import SuccessMsgPage from "../components/common/SuccessMsgPage";
import Expense from "../components/Pages/AppPAges/Expense/expense";
import Login from "../components/common/Login";
import Purchase from "../components/Pages/AppPAges/Purchase/purchase";
import GeneralLedgerATM from "../components/Pages/AppPAges/GeneralLedger/GeneralLedgerATM";
import GeneralLedgerBank from "../components/Pages/AppPAges/GeneralLedger/GeneralLedgerBank";
import GeneralLedgerOwnerDistribution from "../components/Pages/AppPAges/GeneralLedger/GeneralLedgerOwnerDistribution";
import GeneralLedgerOtherIncome from "../components/Pages/AppPAges/GeneralLedger/GeneralLedgerOtherIncome";
import GeneralLedgerSalesTax from "../components/Pages/AppPAges/GeneralLedger/GeneralLedgerSalesTax";
import GeneralLedgerBankLedger from "../components/Pages/AppPAges/GeneralLedger/GeneralLedgerBankLedger";
import ManageSettings from "../components/Pages/AppPAges/ManageSettings/ManageSettings";
import StoreInformation from "../components/Pages/AppPAges/ManageSettings/StoreInformation";
import ManageUsers from "../components/Pages/AppPAges/ManageSettings/ManageUsers";
import ManageDepartments from "../components/Pages/AppPAges/ManageSettings/ManageDepartments";
import ManageVendor from "../components/Pages/AppPAges/ManageSettings/ManageVendor";
import ManageExpenseType from "../components/Pages/AppPAges/ManageSettings/ManageExpenseType";
import OtherIncomeType from "../components/Pages/AppPAges/ManageSettings/OtherIncomeType";
import PayInvoice from "../components/Pages/AppPAges/Dashboard/Invoice/PayInvoice";
import BankDeposit from "../components/Pages/AppPAges/Dashboard/Invoice/BankDeposit";
import AtmDeposit from "../components/Pages/AppPAges/Dashboard/Invoice/AtmDeposit";
import Accounting from "../components/Pages/AppPAges/Account/Accounting";
import Payment from "../components/Pages/AppPAges/Payments/Payment";
import Reports from "../components/Pages/AppPAges/Reports/Reports";

import AddInvoice from "../components/Pages/AppPAges/Dashboard/Invoice/AddInvoice";
import ReportDateSelection from "../components/Pages/AppPAges/Reports/ReportDateSelection";
import AuditTrail from "../components/Pages/AppPAges/Auditrail/AuditTrail";
import LotteryInventory from "../components/Pages/AppPAges/Lottery/LotteryInventory";
import WeeklyInvoiceSettlement from "../components/Pages/AppPAges/Lottery/WeeklyInvoiceSettlement";
import BookMovement from "../components/Pages/AppPAges/Lottery/BookMovement";
import VendingBookMovement from "../components/Pages/AppPAges/Lottery/VendingBookMovement";
import LotteryBookOnHold from "../components/Pages/AppPAges/Lottery/LotteryBookOnHold";
import ManageGames from "../components/Pages/AppPAges/Lottery/ManageGames";
import WebCamBarcodeScanner from "../components/common/WebCamBarcodeScanner";
import GasInvoice from "../components/Pages/AppPAges/Gas/GasInvoice";
import HouseCharges from "../components/Pages/AppPAges/Gas/HouseCharges";
import GasTaxSettings from "../components/Pages/AppPAges/Gas/GasTaxSettings";
import GasSettings from "../components/Pages/AppPAges/Gas/GasSettings";
import Payroll from "../components/Pages/AppPAges/Payroll/Payroll";
import GeneralLedgerCashLedger from "../components/Pages/AppPAges/GeneralLedger/GeneralLedgerCashLedger";
import Dashboard from '../components/Pages/AppPAges/Dashboard/Dashboard'
import MainDashboard from "../components/Pages/AppPAges/Dashboard/MainDashboard";
import Report from "../components/Pages/AppPAges/ReportUi/Report";

const routes = [
  {
    path: "/",
    component: AddInvoice,
    auth: true,
  },

  {
    path: "/owner-dashboard",
    component: MainDashboard,
    auth: true,
  },
  {
    path: "/payInvoice",
    component: PayInvoice,
    auth: true,
  },

  {
    path: "/bankDeposit",
    component: BankDeposit,
    auth: true,
  },
  {
    path: "/atmDeposit",
    component: AtmDeposit,
    auth: true,
  },
  {
    path: "/login",
    component: Login,
    auth: false,
  },
  {
    path: "/create-user",
    component: SignUp,
    auth: false,
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    auth: false,
  },
  {
    path: "/auth/pass-reset/:temp_token",
    component: ConfirmPassword,
    auth: false,
  },
  {
    path: "/success-msg",
    component: SuccessMsgPage,
    auth: false,
  },
  {
    path: "/expense",
    component: Expense,
    auth: true,
  },
  {
    path: "/purchase",
    component: Purchase,
    auth: true,
  },
  {
    path: "/ATM",
    component: GeneralLedgerATM,
    auth: true,
  },
  {
    path: "/Bank",
    component: GeneralLedgerBank,
    auth: true,
  },
  {
    path: "/store-information",
    component: StoreInformation,
    auth: true,
  },
  {
    path: "/manage-users",
    component: ManageUsers,
    auth: true,
  },
  {
    path: "/manage-settings",
    component: ManageSettings,
    auth: true,
  },
  {
    path: "/manage-vendor",
    component: ManageVendor,
    auth: true,
  },
  {
    path: "/manage-departments",
    component: ManageDepartments,
    auth: true,
  },
  {
    path: "/accounting",
    component: Accounting,
    auth: true,
  }, {
    path: "/reportDateSelection",
    component: ReportDateSelection,
    auth: true,
  },
  {
    path: "/reports",
    component: Reports,
    auth: true,
  },

  {
    path: "/payroll",
    component: Payroll,
    auth: true,
  },

  {
    path: "/auditrail",
    component: AuditTrail,
    auth: true,
  },
  {
    path: "/payments",
    component: Payment,
    auth: true,
  },
  {
    path: "/OwnerDist",
    component: GeneralLedgerOwnerDistribution,
    auth: true,
  },
  {
    path: "/OtherIncome",
    component: GeneralLedgerOtherIncome,
    auth: true,
  },
  {
    path: "/SalesTax",
    component: GeneralLedgerSalesTax,
    auth: true,
  },
  {
    path: "/bankLedger",
    component: GeneralLedgerBankLedger,
    auth: true,
  },
  {
    path:"/cashLedger",
    component:GeneralLedgerCashLedger,
    auth:true,
  },
  {
    path: "/manage-expense-type",
    component: ManageExpenseType,
    auth: true,
  },
  {
    path: "/other-income-type",
    component: OtherIncomeType,
    auth: true,
  },
  {
    path: "/lottery-inventory",
    component:LotteryInventory,
    auth: true,
  },
  {
    path: "/lottery-inventory",
    component:LotteryInventory,
    auth: true,
  },
  {
    path: "/weekly-invoice-settlement",
    component:WeeklyInvoiceSettlement,
    auth: true,
  },
  {
    path: "/book-movement",
    component:BookMovement,
    auth: true,
  },
  {
    path: "/vending-book-movement",
    component:VendingBookMovement,
    auth: true,
  },
  {
    path: "/lottery-book-on-hold",
    component:LotteryBookOnHold,
    auth: true,
  },
  {
    path: "/manage-games",
    component:ManageGames,
    auth: true,
  },
  {
    path: "/gas-invoice",
    component:GasInvoice,
    auth: true,
    featureFlag: "gas_report_feature", 
  },
  {
    path: "/house-charges",
    component:HouseCharges,
    auth: true,
    featureFlag: "gas_report_feature", 
  },
  {
    path: "/gas-tax-settings",
    component:GasTaxSettings,
    auth: true,
    featureFlag: "gas_report_feature", 
  },
  {
    path: "/gas-settings",
    component:GasSettings,
    auth: true,
    featureFlag: "gas_report_feature", 
  },
  {
    path: "/barcode",
    component:WebCamBarcodeScanner,
    auth: true,
  },
  {
    path: "/report-preview",
    component:Report,
    auth: false,
  },
];
export const getRoutes = () => routes;
