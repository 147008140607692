import React, { useState, useEffect, useCallback } from "react";
import { Route, Routes, useLocation, useNavigate, Navigate } from "react-router-dom";
import { getRoutes } from "../../../config/ComponentRegister";
import Auth from "../AuthCheck";

// Protected Route wrapper component
const FeatureProtectedRoute = ({ component: Component, featureFlag, enabledFeatures, auth }) => {
  const isFeatureEnabled = !featureFlag || enabledFeatures[featureFlag];

  if (!isFeatureEnabled) {
    return <Navigate to="/" replace />;
  }

  return <Auth check={auth} component={<Component />} />;
};

const RouteManager = () => {
  const [enabledFeatures, setEnabledFeatures] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const loadSettings = useCallback(() => {
    const settings = JSON.parse(localStorage.getItem("settings") || "{}");
    const firstLoginFlag = localStorage.getItem("firstLoginAfterSettingFlag");
    const newEnabledFeatures = {
      gas_report_feature: settings.gas_report_feature || false,
    };

    if (firstLoginFlag === "true" && settings.default_login_step_report_page) {
      navigate("/reportDateSelection");
      localStorage.removeItem("firstLoginAfterSettingFlag");
    }

    // Check if current route should be protected
    const currentRoute = getRoutes().find(route => location.pathname.startsWith(route.path));
    if (currentRoute?.featureFlag) {
      const isFeatureEnabled = newEnabledFeatures[currentRoute.featureFlag];
      if (!isFeatureEnabled) {
        navigate('/', { replace: true });
      }
    }

    setEnabledFeatures(newEnabledFeatures);
    setIsLoading(false);
  }, [location.pathname, navigate]);

  useEffect(() => {
    // Initial load
    loadSettings();

    // Handle storage changes from other tabs
    const handleStorageChange = (event) => {
      if (event.key === "settings") {
        loadSettings();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    // Poll for same-tab changes
    const interval = setInterval(loadSettings, 500);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      clearInterval(interval);
    };
  }, [loadSettings]);

  if (isLoading) {
    return null; // Or a loading spinner
  }

  return (
    <Routes>
      {getRoutes().map(({ path, component, auth, featureFlag }, index) => (
        <Route
          key={index}
          path={path}
          element={
            <FeatureProtectedRoute
              component={component}
              featureFlag={featureFlag}
              enabledFeatures={enabledFeatures}
              auth={auth}
            />
          }
        />
      ))}
    </Routes>
  );
};

export default RouteManager;