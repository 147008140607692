/* eslint-disable no-unused-vars */
/* eslint-disable no-self-compare */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import SettingTable from "../ManageSettings/SettingTable";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import AuthContext from "../../.././../utils/secure-route/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import Swal from "sweetalert2";

export default function OwnerDistribution() {
  const { Post, Get, Delete, Put } = useApi();
  const { user } = useContext(AuthContext);
  const storeid = user.store;

  // State for data and modal
  const [distributions, setDistributions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [ownerList, setOwnerList] = useState([]);
  const [banks, setBanks] = useState([]);

  // Form data state
  const [formData, setFormData] = useState({
    date: "",
    transaction_type: "Owner Distributions",
    owner: "",
    pay_method: "",
    bank: "",
    pay_method_status: "",
    cheque_no: "",
    note: "",
    amount: "",
  });

  // Columns for the table
  const columns = [
    { header: "Date", field: "date" },
    { header: "Owner", field: "owner_name" },
    { header: "Payment Type", field: "pay_method" },
    { header: "Amount", field: "amount" },
    { header: "Bank", field: "bank_name" },
    { header: "Cheque No", field: "cheque_no" },
    { header: "Notes", field: "note" },
  ];

  // Fetch initial data
  useEffect(() => {
    fetchOwnerDistributions();
    fetchOwnerList();
    fetchBanks();
  }, []);

  const fetchOwnerDistributions = async () => {
    try {
      // Fetch transaction data from the API
      const response = await Get("transactionData");

      // Filter the data to get only "ATM Deposit" transactions
      const filteredDistributions = filterByTransactionType(response, "Owner Distributions");
      setDistributions(filteredDistributions); // Update state with the filtered data


    } catch (err) {
      // Handle errors that occur during the fetch or processing
      console.error("Error fetching owner distributions:", err);
      toast.error("Failed to fetch owner distributions"); // Display error message to the user
      setDistributions([]); // Set state to an empty array on error
    }
  };

  function filterByTransactionType(dataArray, transactionType) {
    return dataArray.filter(record => record.transaction_type === transactionType);
  }
  const fetchOwnerList = async () => {
    try {
      const response = await Get("user");

      // Check if the response has the structure with `results`
      if (response && Array.isArray(response.results)) {
        // Filter only store owners
        const storeOwners = response.results.filter(
          owner => owner.ms_role_name === "store owner"
        );
        setOwnerList(storeOwners);
      } else {
        console.error("Invalid response structure:", response);
        toast.error("Failed to fetch owner list");
      }
    } catch (err) {
      console.error("Error fetching owner list:", err);
      toast.error("Failed to fetch owner list");
    }
  };


  const fetchBanks = async () => {
    try {
      const response = await Get("bank");


      if (response && Array.isArray(response) && response.length > 0) {
        // Directly setting the banks data
        setBanks(response);
      } else {
        toast.error("No bank data found");
      }
    } catch (err) {
      console.error("Error fetching banks:", err);
      toast.error("Failed to fetch bank list");
    }
  };


  const today = new Date().toISOString().split("T")[0];
  // Toggle Modal
  const toggleModal = () => {
    setShowModal(!showModal);
    if (!showModal) {
      // Reset form when opening modal
      setFormData({
        date: today,
        owner: "",
        pay_method: "",
        bank: "",
        cheque_no: "",
        pay_method_status: "",
        note: "",
        amount: "",
      });
      setIsEditing(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => {
      const updatedFormData = {
        ...prevState,
        [name]: value
      };

      // Log the pay_method when it changes
      if (name === "pay_method") {
        console.log("Pay method updated to:", updatedFormData.pay_method);

      }

      return updatedFormData;
    });
  };


  // Handle date change
  const handleDateChange = (e) => {
    const { value } = e.target;

    // Ensure the date value is properly formatted to YYYY-MM-DD
    setFormData((prevData) => ({
      ...prevData,
      date: value, // This will be in the correct format for the <input type="date">
    }));
  };
  const handleOwnerDistribution = async (e) => {
    e.preventDefault();


    formData.pay_method_status = null;
    // Validate form data
    if (!formData.date || !formData.owner || !formData.pay_method || !formData.amount) {
      toast.error("Please fill in all required fields");
      return;
    }



    // If pay_method is "cheque", ensure bank & cheque_no are filled
    if (formData.pay_method === "cheque") {
      if (!formData.bank) {
        toast.error("Please select a Bank.");
        return;
      }
      if (!formData.cheque_no) {
        toast.error("Please enter a Cheque Number.");
        return;
      }
    }

    // If pay_method is "bank", ensure bank is filled
    if (formData.pay_method === "bank" && !formData.bank) {
      toast.error("Please select a Bank.");
      return;
    }

    // Find the owner by full name
    let selectedOwner = ownerList.find(owner =>
      `${owner.first_name} ${owner.last_name}`.trim().toLowerCase() === formData.owner.trim().toLowerCase()
    );

    // If no full name match, try matching by first name
    if (!selectedOwner) {
      selectedOwner = ownerList.find(owner =>
        owner.first_name.trim().toLowerCase() === formData.owner.trim().toLowerCase()
      );
    }

    // Prepare the payload with the owner details
    const payload = {
      ...formData,
      transaction_type: "Owner Distributions",
      store: storeid,
      owner_name: formData.owner,
      owner: selectedOwner ? selectedOwner.id : null,
      pay_method_status: null
    };


    Object.keys(payload).forEach(key => {
      if ((payload[key] === undefined || payload[key] === null) && key !== "pay_method_status") {
        delete payload[key];
      }
    });

    try {
      let response;

      if (isEditing && formData.id) {
        // Update existing distribution (PUT request)
        response = await Put(`transactionData`, formData.id, payload);
      } else {
        // Create new distribution (POST request)
        response = await Post("transactionData", payload);
      }

      if (response) {
        toast.success(isEditing ? "Distribution Updated" : "Distribution Added");
        fetchOwnerDistributions();  // Refresh the list
        toggleModal();  // Close modal
      } else {
        toast.error("Failed to submit distribution");
      }
    } catch (err) {
      console.error("Error submitting owner distribution:", err);
      toast.error("Error submitting distribution");
    }
  };

  const handleEditDistribution = (distribution) => {
    // Find the owner based on the owner_name in the distribution
    const selectedOwner = ownerList.find(owner =>
      `${owner.first_name} ${owner.last_name}`.trim().toLowerCase() === distribution.owner_name.trim().toLowerCase()
    );

    // Prepare the form data for editing
    setFormData({
      ...distribution,
      // Explicitly set the owner to the full name
      owner: distribution.owner_name,
      // Ensure other fields are correctly mapped
      date: distribution.date,
      amount: distribution.amount,
      pay_method: distribution.pay_method,
      bank: distribution.bank,
      cheque_no: distribution.cheque_no,
      note: distribution.note
    });

    setIsEditing(true);
    setShowModal(true);
  };

  const handleDeleteDistribution = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    // Check if the user clicked the confirm button
    if (!result.isConfirmed) return; // Exit if the user cancels
    try {
      await Delete("transactionData", id);

      setDistributions((prevDistributions) =>
        prevDistributions.filter((distribution) => distribution.id !== id)
      );

      toast.success("Owner Distribution deleted successfully");
    } catch (error) {
      // Log the error and show an error message
      console.error("Error deleting Owner Distribution:", error);
      toast.error("Error deleting Owner Distribution");
    }
  };


  return (
    <>
      <ToastContainer />
      <div className="main-container mt-2">
        <div className="d-flex p-2">
          <div className="expensecontainer d-flex flex-column pd-2">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h3 className="mb-1">Owner Distributions</h3>
              <button className="btn btn-primary cus" onClick={toggleModal}>
                + Add Owner Distribution
              </button>
            </div>

            <SettingTable
              data={distributions}
              columns={columns}
              showFooter={true}
              showAction={true}
              showExport={true}
              showFilter={false}
              onEdit={handleEditDistribution}
              onDelete={handleDeleteDistribution}
            />

            {/* Modal content remains the same as in the previous version */}
            {showModal && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0,0,0,0.5)",
                  zIndex: 1000,
                }}
                onClick={toggleModal}
              >

                <div
                  onClick={(e) => e.stopPropagation()}
                  style={{
                    width: "700px",
                    backgroundColor: "white",
                    margin: "100px auto",
                    borderRadius: "8px",
                    position: "relative",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div className="modal-header" style={{ border: "none" }}>
                    <h5 className="modal-title p-2" style={{ fontWeight: "bold" }}>
                      {isEditing ? "Edit Owner Distribution" : "Add Owner Distribution"}
                    </h5>

                    <svg
                      onClick={toggleModal}
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        marginRight: "10px",
                        marginBottom: '10px',
                        cursor: "pointer",
                      }}
                    >
                      <path
                        d="M1 1L13 13M13 1L1 13"
                        stroke="black"
                        stroke-linecap="round"
                      />
                    </svg>
                  </div>
                  <div className="modal-body">
                    <form onSubmit={handleOwnerDistribution}>
                      <div className="form-row form-group col-md-4 position-relative">
                        <input
                          style={{
                            backgroundColor: "#0023000C",
                            borderRadius: "10px",
                            height: "52px",
                          }}
                          type="date"
                          className="form-control"
                          name="date"
                          placeholder="Bill Date"
                          value={formData.date}
                          onChange={handleDateChange}
                          required
                        />
                      </div>

                      <div className="mb-3 mt-2 col-md-5">
                        <select
                          style={{
                            backgroundColor: "#0023000C",
                            borderRadius: "10px",
                            height: "52px",
                          }}
                          className="form-control"
                          name="owner"
                          value={formData.owner || ''}
                          onChange={(e) => {
                            const selectedOwnerFullName = e.target.value;
                            setFormData(prevState => ({
                              ...prevState,
                              owner: selectedOwnerFullName,
                            }));
                          }}
                          required
                        >
                          <option value="">Select Owner</option>
                          {ownerList.length > 0 &&
                            ownerList.map((owner) => {
                              const fullName = `${owner.first_name} ${owner.last_name}`.trim();
                              return (
                                <option key={owner.id} value={fullName}>
                                  {fullName}
                                </option>
                              );
                            })}
                        </select>
                      </div>

                      <div className="d-flex col-md-12 p-1">
                        <div className="form-check me-3">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="pay_method"
                            value="cash"
                            checked={formData.pay_method === "cash"}
                            onChange={handleChange}
                          />
                          <label className="form-check-label">Cash</label>
                        </div>
                        <div className="form-check me-3">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="pay_method"
                            value="cheque"
                            checked={formData.pay_method === "cheque"}
                            onChange={handleChange}
                          />
                          <label className="form-check-label">Cheque</label>
                        </div>
                        <div className="form-check">
                          <input
                            required
                            type="radio"
                            className="form-check-input"
                            name="pay_method"
                            value="bank"
                            checked={formData.pay_method === "bank"}
                            onChange={handleChange}
                          />
                          <label className="form-check-label">
                            Bank Card (ACH/EFT)
                          </label>
                        </div>
                      </div>

                      <div className="row">
                        {(formData.pay_method === "bank" || formData.pay_method === "cheque") && (
                          <div className="mb-3 mt-2 col-md-4">
                            <select
                              style={{
                                backgroundColor: "#0023000C",
                                borderRadius: "10px",
                                height: "52px",
                              }}
                              className="form-control"
                              name="bank"
                              value={formData.bank}
                              onChange={handleChange}
                            >
                              <option value="">Select a bank</option>
                              {banks.map((bank) => (
                                <option key={bank.id} value={bank.id}>
                                  {bank.name}
                                </option>
                              ))}

                            </select>
                          </div>
                        )}

                        {formData.pay_method === "cheque" && (
                          <div className="mb-3 mt-2 col-md-4">
                            <input
                              style={{
                                backgroundColor: "#0023000C",
                                borderRadius: "10px",
                                height: "52px",
                                border: '1px lightgray solid',
                              }}
                              type="text"
                              name="cheque_no"
                              value={formData.cheque_no || ""}
                              placeholder="Cheque No"
                              onChange={handleChange}
                            />
                          </div>
                        )}
                      </div>

                      <div className="form-row d-flex col-md-12">
                        <textarea
                          className="form-control expenformtextarea"
                          style={{ backgroundColor: "#fafbfa", marginTop: "10px" }}
                          id="note"
                          rows="3"
                          placeholder="Notes"
                          name="note"
                          value={formData.note}
                          onChange={handleChange}
                        ></textarea>
                      </div>

                      <div className="form-row d-flex col-md-6">
                        <div className="containercolor">
                          <div className="input-group p-2 mt-2" style={{
                            backgroundColor: "#0023000C",
                            borderRadius: "10px",
                            height: "52px",
                            border: '1px lightgray solid'
                          }}>
                            <div
                              className="input-group-prepend"
                              style={{
                                background: "#F5F5F5",
                                height: "100%",
                                borderRadius: "10px 0 0 10px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span className="input-group-text prefixtext"
                                style={{
                                  border: "none",
                                  fontSize: "14px",
                                }}
                              >USD</span>
                            </div>
                            <input
                              type="text"
                              name="amount"
                              className="form-control sampletext"
                              value={formData.amount}
                              onChange={handleChange}
                              placeholder="Amount"
                              aria-label="Username"
                              aria-describedby="addon-wrapping"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="modal-footer" style={{ border: "none" }}>
                        <button
                          type="button"
                          className="btn btn-secondary-outline"
                          onClick={toggleModal}
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}