/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import ReusableModal from "./ReusableModal";
import config from "../../../../config/Global.json";
import "./Settings.css";
import AuthContext from "../../../../utils/secure-route/AuthContext";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import { toast, ToastContainer } from "react-toastify";
import CustomSwitch from "./CustomSwitch";
import ManageUserTable from "./ManageUserTable";
import Swal from "sweetalert2";


function ManageUsers() {
  const { user } = useContext(AuthContext);
  const storeId = user.store;
  const [activeModal, setActiveModal] = useState(false);
  const [activeStoreModal, setActiveStoreModal] = useState(false);
  const [assignOwnerModal, setAssignOwnerModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [role, setRole] = useState("");
  const [status, setStatus] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [ownerEmail, setOwnerEmail] = useState(""); // State for owner's email
  const { Get, Post, Patch, Delete } = useApi();



  const [loading, setLoading] = useState(false);
  const [pincodeErrorMsg, setPincodeErrorMsg] = useState("");
  const [pincodeErrorFlag, setPincodeErrorFlag] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    store_name: "",
    address_line1: "",
    pincode: "",
    city: "",
    state: "",
    country: "",
  });


  useEffect(() => {
    fetchUsersAndRoles();
  }, []);


  const fetchUsersAndRoles = async () => {
    try {
      const [usersResponse, rolesResponse] = await Promise.all([
        Get("user"),
        Get("msRole"),
      ]);

      if (usersResponse.error) throw new Error(usersResponse.error);
      if (rolesResponse.error) throw new Error(rolesResponse.error);

      setUsers(
        Array.isArray(usersResponse.results) ? usersResponse.results : []
      );
      const roles = Array.isArray(rolesResponse.results)
        ? rolesResponse.results
        : [];
      setRoles(roles.map((role) => ({ id: role.id, name: role.role_name })));
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching users or roles.");
    }
  };


  const handleShowUserModal = (user = null) => {
    if (user) {
      // If user is provided, it means we are editing
      setCurrentUserId(user.id);
      setFullName(user.first_name);
      setEmail(user.email);
      setPhoneNumber(user.contact_no);
      setRole(user.ms_role);
      setStatus(user.is_active);
    } else {
      // Reset fields for adding a new user
      setCurrentUserId(null);
      setFullName("");
      setEmail("");
      setPhoneNumber("");
      setRole("");
      setStatus(false);
    }
    setActiveModal(true);
  };

  const handleCloseUserModal = () => setActiveModal(false);

  const handleCloseAssignOwnerModal = () => {
    setAssignOwnerModal(false);  // Close the modal
    setOwnerEmail("");  // Clear the owner's email state
  };
  const handleCloseStoreModal = () => setActiveStoreModal(false);

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });
  
    // Check if the user clicked the confirm button
    if (!result.isConfirmed) return; // Exit if the user cancels
  
    try {
      await Delete("user", id);
      setUsers((prev) => prev.filter((user) => user.id !== id)); // Remove the user from the list
      toast.success("User deleted successfully!");
    } catch (error) {
      console.error("Error deleting user:", error);
  
      // Check if the error response contains the specific message
      if (error.response && error.response.data && error.response.data.detail === "Store owners cannot be deleted.") {
        toast.error("Store owners cannot be deleted.");
      } else {
        toast.error("Error deleting user!");
      }
    }
  };
  const handleSaveUser = async () => {
    const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/; // Email regex without #
    const phoneRegex = /^[0-9]{10}$/; // US phone number regex for exactly 11 digits (starts with '1' for the country code)
  
    // Validate email
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address (no '#' allowed).");
      return;
    }
  
    // Validate phone number
    if (!phoneRegex.test(phoneNumber)) {
      toast.error("Please enter a valid US phone number (11 digits, starts with '1').");
      return;
    }
  
    // Derive role name from roles array
    const roleName = roles.find((r) => String(r.id) === String(role))?.name || "";
  
    const payload = {
      first_name: fullName,
      email: email,
      contact_no: phoneNumber,
      ms_role: role, // Role ID
      store: storeId,
      is_active: status,
      groups: [],
      ms_role_name: roleName, // Derived role name
    };
  
    try {
      if (currentUserId) {
        // Update user
        await Patch("user", currentUserId, payload);
  
        // Update local state
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === currentUserId ? { ...user, ...payload } : user
          )
        );
  
        toast.success("User updated successfully!");
      } else {
        // Add new user
        await Post("user", payload);
  
        // Fetch updated users
        const updatedUsers = await Get("user");
        setUsers(updatedUsers.results);
  
        toast.success("User added successfully!");
      }
  
      handleCloseUserModal();
    } catch (error) {
      console.error("Error saving user:", error);
  
      // Check for specific error responses
      if (error.response && error.response.data) {
        if (error.response.data.ms_role === "You cannot change the role of a store owner.") {
          toast.error("Store owner roles cannot be changed.");
        } else if (error.response.data.is_active === "Store owners cannot be deactivated.") {
          toast.error("Store owners cannot be deactivated.");
        } else {
          toast.error("Error saving user!");
        }
      } else {
        toast.error("Error saving user!");
      }
    }
  };
  

  const handleAssignOwnerSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await Post("assignAsOwner", { email: ownerEmail });
  
      // If successful, show success message
      toast.success("User assigned as owner successfully!");
      setOwnerEmail("");  // Clear input
      fetchUsersAndRoles();  // Refresh the users and roles
      handleCloseAssignOwnerModal();  // Close the modal
  
    } catch (error) {
      console.error("Error assigning owner:", error);
  
      // Check if the error contains a specific message
      if (error.response && error.response.data && error.response.data.error === "User with this email does not exist.") {
        toast.error("The email you provided does not exist.");
      } else {
        toast.error("Error assigning owner!");
      }
    }
  };
  

  const handleSwitchChange = (id) => {
    setStatus((prevStatus) => !prevStatus);
  };

  //  store api calling
  const fetchLocationDetails = async (pincode) => {
    const apiUrl = `${config.api.host}${config.api.getPincodeData}`;
    try {
      const response = await Post(apiUrl, { pincode });
      const { city, state, country } = response.data;

      setFormData((prevData) => ({
        ...prevData,
        city: city || "",
        state: state || "",
        country: country || "",
      }));

      setPincodeErrorMsg(""); // Clear error message
      setPincodeErrorFlag(false);
    } catch (error) {
      setPincodeErrorMsg("Failed to fetch location details.");
      setPincodeErrorFlag(true);
    }
  };

  // Handle pincode change
  const handlePincodeChange = (e) => {
    const { value } = e.target;

    // Allow only numeric input
    const numericValue = value.replace(/[^0-9]/g, '');

    setFormData((prevData) => ({
        ...prevData,
        pincode: numericValue,
        ...(numericValue.trim() === "" && { city: "", state: "", country: "" }),
    }));

    // Trigger fetching location details if the length is valid
    if (numericValue.length === 5 || numericValue.length === 6) {
        fetchLocationDetails(numericValue);
    }
};
  // Handle pincode blur
  const handlePincodeBlur = () => {
    const { pincode } = formData;
    if (pincode.length === 5 || pincode.length === 6) {
      fetchLocationDetails(pincode);
      setPincodeErrorMsg("");
      setPincodeErrorFlag(false);
    } else {
      setPincodeErrorMsg("Pincode must be 5 or 6 digits.");
      setPincodeErrorFlag(true);
    }
  };

  // Validate form fields
  const validateForm = () => {
    const { email, store_name, address_line1, pincode, city, state, country } = formData;
    if (!email || !store_name || !address_line1 || !pincode || !city || !state || !country) {
      alert("All fields are required!");
      return false;
    }
    if (!/^\d{5,6}$/.test(pincode)) {
      alert("Pincode must be 5 or 6 digits.");
      return false;
    }
    return true;
  };

  // Save store data //

  const handleSaveStore = async () => {
    if (!validateForm()) return;

    setLoading(true);
    try {
      const response = await Post('additionalStore', formData);
      toast.success("Store added successfully!");

      setFormData({
        email: "",
        store_name: "",
        address_line1: "",
        pincode: "",
        city: "",
        state: "",
        country: "",
      });

      setActiveStoreModal(false);
    } catch (error) {
      console.error("Error adding store:", error);
      toast.error("Failed to add store. Please try again.");
    } finally {
      setLoading(false);
    }
  };


  const handleShowStoreModal = async (store = null) => {
    // Reset the form data first
    const initialFormData = {
      email: "",
      store_name: "",
      address_line1: "",
      pincode: "",
      city: "",
      state: "",
      country: "",
    };

    if (store) {
      // Populate form data if store is provided
      setFormData({
        email: "",
        store_name: "",
        address_line1: "",
        pincode: "",
        city: "",
        state: "",
        country: "",
      });
    } else {
      // Fetch user data and set email if adding a new store
      try {
        const response = await Get("user");
        const currentUser = response.results[0];
        // Update email in form data
        initialFormData.email = currentUser?.email || "";
      } catch (error) {
        console.error("Error fetching user data:", error);
      }

      setFormData(initialFormData);
    }

    setActiveStoreModal(true);
  };



  const columns = [
    { header: "Name", field: "first_name" },
    { header: "Email Address", field: "email" },
    { header: "Phone Number", field: "contact_no" },
    { header: "Role", field: "ms_role_name" },
    { header: "Join Date", field: "joining_date" },
    { header: "Status", field: "is_active" },
  ];

  return (
    <>
      <ToastContainer />
      <div className="due-days">
        <div className="header-row">
          <div className="setting-title">Manage Users</div>
          <div className="btn-position">
            <button
              className="vendor-add-button"
              onClick={() => handleShowUserModal()} // Open modal for adding a user
            >
              + Add New User
            </button>
            <button
              className="vendor-add-button"
              onClick={() => handleShowStoreModal()} // Open modal for adding a user
            >
              + Add New Store
            </button>
            <button
              className="vendor-add-button"
              onClick={() => setAssignOwnerModal(true)} // Open modal for assigning an owner
            >
              + Assign Existing User As Owner
            </button>
          </div>
        </div>
        <ManageUserTable
          data={users}
          columns={columns}
          showAction={true}
          showFooter={true}
          onEdit={(handleEdit) => handleShowUserModal(handleEdit)} // Open modal for editing user
          onDelete={handleDelete}
        />
      </div>

      {activeModal && (
        <ReusableModal
          show={true}
          handleClose={handleCloseUserModal}
          title={currentUserId ? "Edit User" : "Add New User"}
          width="1054px"
        >
          <form>
            <div className="form-group d-flex flex-column">
              <div className="d-flex align-items-center mb-3">
                <input
                  type="text"
                  className="input-field"
                  placeholder="Full Name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  style={{ width: "483px", marginRight: "10px" }}
                />
                <input
                  type="email"
                  className="input-field"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ width: "483px", marginRight: "10px" }}
                />
              </div>
              <div className="d-flex align-items-center mb-3">
                <input
                  type="tel"
                  className="input-field"
                  placeholder="Phone Number (without country code)"
                  value={phoneNumber}
                  onChange={(e) => {

                    const input = e.target.value.replace(/[^0-9]/g, '');
                    if (input.length <= 10) {
                      setPhoneNumber(input);
                    }
                  }}
                  maxLength={10}
                  style={{ width: "483px", marginRight: "10px" }}
                />
                <select
                  className="input-field"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  style={{ width: "483px", marginRight: "10px" }}
                >
                  <option value="">Select Role for User</option>
                  {roles.map((r) => (
                    <option key={r.id} value={r.id}>
                      {r.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="d-flex align-items-center mb-2">
                <label className="mr-2" style={{ fontSize: "18px" }}>
                  Status
                </label>
                <CustomSwitch
                  setting={status} // Pass status to determine the switch's state
                  isActive={status}  // This enables/disables the switch
                  handleSwitchChange={() => setStatus(!status)} // Toggle status
                  disabled={status === false} // Disable switch if status is false
                />
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  style={{
                    marginRight: "40px",
                    padding: "4px 8px",
                    backgroundColor: "#4545db",
                    color: "white",
                    border: "none",
                    borderRadius: "40px",
                    cursor: "pointer",
                    width: "124px",
                    height: "42px",
                    fontSize: "18px",
                  }}
                  onClick={handleSaveUser}
                  type="button"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </ReusableModal>
      )}


      {/* / store modal/ */}

      {activeStoreModal && (
        <ReusableModal
          show={true}
          handleClose={handleCloseStoreModal}
          title={"Add New Store"}
          width="1054px"
        >
          <form>
            <div className="form-group">
              {/* Wrapper for Flexbox */}
              <div className="d-flex flex-wrap">
                {/* Left Side */}
                <div style={{ flex: "1", marginRight: "20px" }}>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="input-field"
                      placeholder="Enter Store Name"
                      value={formData.store_name}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          store_name: e.target.value,
                        }))
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="input-field"
                      placeholder="Enter Store Address"
                      value={formData.address_line1}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          address_line1: e.target.value,
                        }))
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="input-field"
                      placeholder="City"
                      value={formData.city}
                      readOnly
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>

                {/* Right Side */}
                <div style={{ flex: "1" }}>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="input-field"
                      placeholder="Pincode"
                      value={formData.pincode}
                      onChange={handlePincodeChange}
                      onBlur={handlePincodeBlur}
                      style={{ width: "100%" }}
                      maxLength={5}
                    />
                  </div>
                  {pincodeErrorFlag && (
                    <span className="error-text">{pincodeErrorMsg}</span>
                  )}
                  <div className="mb-3">
                    <input
                      type="text"
                      className="input-field"
                      placeholder="State"
                      value={formData.state}
                      readOnly
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="input-field"
                      placeholder="Country"
                      value={formData.country}
                      readOnly
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>

              {/* Submit Button */}
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  style={{
                    marginRight: "40px",
                    padding: "4px 8px",
                    backgroundColor: "#4545db",
                    color: "white",
                    border: "none",
                    borderRadius: "40px",
                    cursor: "pointer",
                    width: "124px",
                    height: "42px",
                    fontSize: "18px",
                  }}
                  onClick={handleSaveStore}
                  type="button"
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </ReusableModal>
      )}


      {assignOwnerModal && (
        <ReusableModal
          show={true}
          handleClose={handleCloseAssignOwnerModal}
          title="Assign Admin"
          width="600px"
        >
          <form onSubmit={handleAssignOwnerSubmit}>
            <div className="form-group d-flex align-items-center mt-1">
              <input
                type="email"
                className="input-field"
                placeholder="Email Address"
                value={ownerEmail}
                onChange={(e) => setOwnerEmail(e.target.value)}
                style={{ width: "483px", marginRight: "20px" }}
                required
              />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  style={{
                    marginRight: "40px",
                    padding: "4px 8px",
                    backgroundColor: "#4545db",
                    color: "white",
                    border: "none",
                    borderRadius: "40px",
                    cursor: "pointer",
                    width: "124px",
                    height: "42px",
                    fontSize: "18px",
                  }}
                  onClick={handleAssignOwnerSubmit}
                  type="button"
                >
                  Submit
                </button>
              </div>
            </div>


          </form>

        </ReusableModal>
      )}
    </>
  );
}

export default ManageUsers;
