/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../../../utils/secure-route/AuthContext";
import DataTable from "../ManageSettings/DataTables";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AddExpenseTypeModal from "../ReusableForm/AddExpenseModal";
import AddVendorModal from "../ReusableForm/AddVendorModal";
import Swal from "sweetalert2";

export default function Expense() {
  const { user } = useContext(AuthContext);
  const storeId = user.store;

  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [showCheckFields, setShowCheckFields] = useState(false);
  const [showBankFields, setShowBankFields] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalVendor, setShowModalVendor] = useState(false);
  const [showModalExpense, setShowModalExpense] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [dueDays, setDueDays] = useState([]);
  const [bank, setBank] = useState([]);
  const today = new Date().toISOString().split("T")[0];

  const [invoicesExpense, setInvoicesExpense] = useState([]);
  const [totalPaid, setTotalPaid] = useState(0.0);
  const [totalReceivedCredit, setTotalReceivedCredit] = useState(0.0);
  const [totalUnpaid, setTotalUnpaid] = useState(0.0);
  const [totalOutstandingCredit, setTotalOutstandingCredit] = useState(0.0);
  const [totalDue, setTotalDue] = useState(0.0);
  const [totalCredit, setTotalCredit] = useState(0.0);
  const [totalExpense, setTotalExpense] = useState(0.0);

  const [formData, setFormData] = useState({
    store: storeId,
    type: "expense",
    invoice_no: "",
    date: today,
    status: "",
    pay_method_status: "",
    pay_method: "",
    amount: "",
    pay_amount: "",
    discount: null,
    remaining_amount: null,
    after_discount: null,
    prepaid_tax: false,
    prepaid_tax_percentage: "",
    vendor_department: "",
    vendor_department_name: "",
    cheque_no: "",
    note: "",
    due_date: null,
    bank: "",
  }

  );

  const [invoices, setInvoices] = useState([]);
  const [editingInvoiceId, setEditingInvoiceId] = useState(null);
  const { Get, Post, Delete, Patch, Put } = useApi();
  const [filteredExpenseTypes, setFilteredExpenseTypes] = useState([]);


  function calculateDaysDifference(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const timeDiff = end - start; // Difference in milliseconds
    const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24)); // Convert to days
    return daysDiff;
  }
  useEffect(() => {
    if (formData.date && formData.due_date) {
      // Calculate the number of days between date and due_date
      const startDate = new Date(formData.date);
      const dueDate = new Date(formData.due_date);

      const timeDiff = dueDate - startDate; // Difference in milliseconds
      const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24)); // Convert to days

      // Update the formData with the calculated days
      setFormData((prevData) => ({
        ...prevData,
        days: daysDiff,  // Set the 'days' field to the calculated number of days
      }));
    }
  }, [formData.date, formData.due_date]);
  // Handle checkbox change
  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      prepaid_tax: checked,
    }));
  };
  const [reloadData, setReloadData] = useState(false);
  useEffect(() => {
    const getInvoiceData = async () => {
      try {
        const response = await Get("invoiceData");
        const activeInvoices = response.filter((invoice) => !invoice.is_deleted);
        setInvoices(activeInvoices);
      } catch (error) {
        console.error("Error fetching invoices:", error);
      }
    };

    getInvoiceData();
  }, [reloadData]);


  const [reloadTotal, setReloadTotal] = useState(false);
  const getExpenseCalculation = async () => {
    Get("expenseCalculation")
      .then((response) => {
        setInvoicesExpense(response);
        setTotalPaid(response.total_paid);
        setTotalReceivedCredit(response.total_received_credit);
        setTotalUnpaid(response.total_unpaid);
        setTotalOutstandingCredit(response.total_outstanding_credit);
        setTotalDue(response.total_due);
        setTotalCredit(response.total_credit);
        setTotalExpense(response.total_purchase || 0); // Default to 0 if not in response
        // setTotalPurchase(response.total_purchase);
      })
      .catch((error) => {
        console.error("Error fetching invoices:", error);
      });
  };
  useEffect(() => {
    getExpenseCalculation();
  }, [reloadTotal]);

  const fetchData = async () => {
    try {
      const [vendorsResponse, serviceResponse, dueDaysResponse, bankResponse] =
        await Promise.all([
          Get("vendorDepartmentData"),
          Get("vendorDepartmentServiceData"),
          Get("dueDaysData"),
          Get("bank"),
        ]);
      setVendors(vendorsResponse);
      setServiceData(serviceResponse);
      setDueDays(dueDaysResponse);
      setBank(bankResponse);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleNewExpenseType = (newExpenseType) => {
    // Add the new expense type to the serviceData array dynamically
    setServiceData((prevData) => [
      ...prevData,
      {
        id: newExpenseType.id, // Assuming the response contains the `id`
        title: newExpenseType.title,
        type: "expense type",
      },
    ]);

    // Reset the formData (dropdown) so the modal can open again
    setFormData((prevData) => ({
      ...prevData,
      vendor_department_service_titles: "", // Reset the value to allow re-opening the modal next time
    }));

    setShowModalExpense(true); // Keep modal open
  };
  const handlePaymentMethodChange = (e) => {
    const value = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      pay_method_status: value,
      pay_method:
        value === "pay_later"
          ? "pay_later"
          : value === "credit_invoice"
            ? "credit_invoice"
            : "",
      status: value === "pay_now" ? "paid" : "unpaid",
    }));
    setShowPaymentOptions(value === "pay_now");
    setShowAdditionalFields(
      value === "credit_invoice" || value === "pay_later"
    );
    setShowCheckFields(false);
    setShowBankFields(false);
  };

  const handleSpecificPaymentMethodChange = (e) => {
    const value = e.target.value;
    setShowCheckFields(value === "cheque");
    setShowBankFields(value === "bank");

    setFormData((prevData) => ({
      ...prevData,
      pay_method: value,
    }));
  };

  const toggleModal = () => {
    setShowModal(!showModal);
    if (showModal) {
      setFormData({
        type: "expense",
        invoice_no: "",
        date: today,
        status: "",
        pay_method_status: "",
        pay_method: "",
        amount: "",
        pay_amount: null,
        discount: null,
        remaining_amount: null,
        after_discount: null,
        prepaid_tax: false,
        prepaid_tax_percentage: "",
        vendor_department: "",
        vendor_department_name: "",
        cheque_no: "",
        note: "",
        due_date: "",
        bank: "",
      });
      setEditingInvoiceId(null);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const updatedData = {
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    };

    if (name === "vendor_department") {
      const selectedVendor = vendors.find((vendor) => vendor.id === value);
      updatedData.vendor_department_name = selectedVendor?.name || "";

      const vendorExpenseTypes = serviceData.filter(
        (service) =>
          selectedVendor?.vendor_department_service_title?.includes(service.title) &&
          service.type === "expense type"
      );

      setFilteredExpenseTypes(vendorExpenseTypes);
      updatedData.vendor_department_service_titles = vendorExpenseTypes.length > 0 ? vendorExpenseTypes[0].title : "";
    }

    if (name === "days") {
      const selectedDueDays = parseInt(value, 10);
      if (!isNaN(selectedDueDays)) {
        const today = new Date();
        const dueDate = new Date(today);
        dueDate.setDate(dueDate.getDate() + selectedDueDays);
        updatedData.due_date = dueDate.toISOString().split("T")[0];
      }
    }

    if (value === "add-expense") {
      setShowModalExpense(true);
    }
  // Handle 'days' field to calculate due date
  if (name === "days") {
    const selectedDueDays = parseInt(value, 10);
    if (!isNaN(selectedDueDays)) {
      const today = new Date();
      const dueDate = new Date(today);
      dueDate.setDate(dueDate.getDate() + selectedDueDays);
      updatedData.due_date = dueDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
    }
  }
   console.log(updatedData)
    setFormData(updatedData);
  };


  const handleEdit = (invoice) => {
    // Check if the invoice is 'partially_paid'
    if (invoice.status === "partially_paid") {
      // Show an error message and return without opening the modal
      toast.error("Cannot edit partially paid invoice.");
      return;  // Prevent editing
    }

    const vendorId = invoice.vendor_department;
    const vendorDepartmentServiceTitles = invoice.vendor_department_service_titles || [];
    const selectedVendor = vendors.find((vendor) => vendor.id === vendorId);

    // Update form data with the invoice information
    const updatedFormData = {
      ...invoice,
      type: "expense",
      vendor_department_name: selectedVendor?.name || "",
      vendor_department_service_titles: vendorDepartmentServiceTitles,
      due_date: invoice.due_date || "",
      amount: invoice.amount || "",
    };

    // Filter expense types based on the selected vendor's department
    const vendorExpenseTypes = serviceData.filter(
      (service) =>
        selectedVendor?.vendor_department_service_title?.includes(service.title) &&
        service.type === "expense type"
    );

    // Set filtered expense types
    setFilteredExpenseTypes(vendorExpenseTypes);
    console.log(updatedFormData)
    // Set the updated form data and open the modal
    setFormData(updatedFormData);
    setEditingInvoiceId(invoice.id);
    setShowModal(true); // Open the modal
  };


  const handleShow = () => setShowModalExpense(true);
  const handleCloseModal = () => {
    setShowModalExpense(false);
  };
  const handleShowVendor = () => setShowModalVendor(true);
  const handleCloseVendor = () => {
    setShowModalVendor(false);
  };
  const handleVendorAdded = (newVendor) => {
    setVendors((prevVendors) => [...prevVendors, newVendor]);
    setFormData({ ...formData, vendor_department: newVendor.id });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check for duplicate invoice number when creating a new invoice
    if (!editingInvoiceId) {
      const isDuplicateInvoiceNo = invoices.some(
        (invoice) => invoice.invoice_no === formData.invoice_no
      );
      if (isDuplicateInvoiceNo) {
        toast.error("Invoice number must be unique.");
        return;
      }
    }

    // Validate required fields
    if (!formData.vendor_department) {
      toast.error("Please Select a Vendor.");
      return;
    }
    if (!formData.invoice_no) {
      toast.error("Invoice number is required.");
      return;
    }
    if (!formData.vendor_department_service_titles) {
      toast.error("Please select an expense type");
      return;
    }
    if (formData.pay_method_status === "pay_later") {
      if (!formData.pay_method) {
        toast.error("Please select a payment method.");
        return;
      }

      if (!formData.due_date) {
        toast.error("Please select a Due Date");
        return;
      }
      if (!formData.amount) {
        toast.error("Please fill in the Amount");
        return;
      }
    }

    if (formData.pay_method_status === "pay_now") {
      if (!formData.pay_method) {
        toast.error("Please select a payment method.");
        return;
      }

      if (formData.pay_method === "cash") {
        if (!formData.amount) {
          toast.error("Please fill in the Amount");
          return;
        }
      }

      formData.due_date = null;

      if (formData.pay_method_status === "pay_now") {
        if (!formData.pay_method) {
          toast.error("Please select a payment method.");
          return;
        }

        if (formData.pay_method === "bank") {
          if (!formData.bank) {
            toast.error("Please select a Bank.");
            return;
          }
          if (!formData.amount) {
            toast.error("Please fill in the Amount");
            return;
          }
        }
      }

      if (formData.pay_method_status === "pay_now") {
        if (!formData.pay_method) {
          toast.error("Please select a payment method.");
          return;
        }

        if (formData.pay_method === "cheque") {
          if (!formData.bank) {
            toast.error("Please select a Bank.");
            return;
          }

          if (!formData.cheque_no) {
            toast.error("Please fill in the Cheque No");
            return;
          }
          if (!formData.amount) {
            toast.error("Please fill in the Amount");
            return;
          }
        }
      }
    }
    // Set the amounts and status based on payment method

    // formData.due_date = null;

    let adjustedAmount = formData.amount;

    // If it's a credit invoice, adjust amounts to be negative


    const updatedFormData = {
      ...formData,
      amount: formData.amount,
    };

    // Special handling for 'pay_now'
    if (formData.pay_method_status === "pay_now") {
      updatedFormData.status = "paid";  // Explicitly set to "paid" for "pay_now"
      updatedFormData.due_date = null;  // No due date for "pay_now"
    }

    try {
      let response;
      if (editingInvoiceId) {
            // Editing an existing invoice
            const originalInvoice = invoices.find((invoice) => invoice.id === editingInvoiceId);
            if (!originalInvoice) {
                console.error("Invoice not found for editingInvoiceId:", editingInvoiceId);
                return;
            }

            // Determine the new status based on payment method and remaining amount
            const newRemainingAmount = Math.max(0, originalInvoice.amount - (formData.pay_amount || 0));
            let newStatus = originalInvoice.status; // Start with the original status

            if (formData.pay_method_status === "pay_now") {
                newStatus = "paid"; // Always "paid" if paid now
            } else if (formData.pay_method_status === "pay_later") {
                newStatus = "unpaid"; // Ensure unpaid if paying later
            } else if (newRemainingAmount < originalInvoice.amount) {
                newStatus = "partially_paid"; // Adjust if partially paid
            }


        // Ensure pay_amount is included when editing
        updatedFormData.pay_amount = formData.pay_amount !== undefined
          ? formData.pay_amount
          : formData.remaining_amount;  // Use remaining_amount if pay_amount is undefined

        // Update status for pay_now
        if (formData.pay_method_status === "pay_now") {
          updatedFormData.status = "paid";  // Ensure "paid" status for "pay_now"
        }
        if (formData.pay_method_status === "pay_later") {
          updatedFormData.status = "unpaid";  // Ensure "paid" status for "pay_now"
        }
        const updatedInvoice = {
          ...updatedFormData,
          invoice_id: editingInvoiceId,
          status: newStatus,
        };


        // Call PUT request to update the invoice
        response = await Post("editInvoice", updatedInvoice);

        if (response.status === 200) {
          setInvoices((prevInvoices) =>
            prevInvoices.map((invoice) =>
              invoice.id === editingInvoiceId ? { ...invoice, ...updatedInvoice } : invoice
            )
          );
          console.log(updatedInvoice)
          toast.success("Invoice updated successfully!");
          setReloadData(!reloadData);
          setReloadTotal(!reloadTotal);
          toggleModal(); // Close the modal after saving

        }
      } else {
        // Creating a new invoice
        const duplicateInvoice = invoices.some(
          (invoice) => invoice.invoice_no === formData.invoice_no
        );
        if (duplicateInvoice) {
          toast.error("Invoice number must be unique.");
          return;
        }

        // Post the data for a new invoice
        Post("createInvoiceAndPrepaidTax", formData)
          .then((response) => {
            toast.success("Invoice submitted successfully!");

            setReloadData(!reloadData);
            setReloadTotal(!reloadTotal);
            toggleModal();
          })
      };
      console.log(formData)
      // Close the modal after submitting
    } catch (error) {
      console.error("Error submitting invoice:", error);
      toast.error(
        "Error submitting invoice: " +
        (error.response ? error.response.data : error.message)
      );
    }
  };



  // const handleDelete = async (id) => {
  //   const result = await Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#d33",
  //     cancelButtonColor: "#3085d6",
  //     confirmButtonText: "Yes, delete it!",
  //   });

  //   // Check if the user clicked the confirm button
  //   if (!result.isConfirmed) return; // Exit if the user cancels
  //   try {
  //     const response = await Post(`deleteInvoice`, { invoice_id: id });
  //     toast.success("Invoice deleted successfully!");
  //     setInvoices((prevInvoices) =>
  //       prevInvoices.filter((invoice) => invoice.id !== id)
  //     );
  //     await getExpenseCalculation();
  //   } catch (error) {
  //     console.error("Error deleting invoice:", error);
  //     toast.error(
  //       "Error deleting invoice: " +
  //       (error.response ? error.response.data : error.message)
  //     );
  //   }
  // };



  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });
    if (!result.isConfirmed) return;

    try {

      const payload = {
        invoice_id: id,
        is_deleted: true,
      };

      const response = await Post(`deleteInvoice`, payload);
      toast.success("Invoice deleted successfully!");
      setInvoices((prevInvoices) =>
        prevInvoices.filter((invoice) => invoice.id !== id)
      );
      await getExpenseCalculation();
    } catch (error) {
      console.error("Error deleting invoice:", error);
      toast.error(
        "Error deleting invoice: " +
        (error.response ? error.response.data : error.message)
      );
    }
  };




  const expenseInvoice = invoices.filter(
    (invoice) => invoice.type === "expense" && !invoice.is_deleted
  );
  /*=========================================*/

  const columns = [
    { header: "Date", field: "date" },
    { header: "Invoice Number", field: "invoice_no" },
    { header: "Vendor Name", field: "vendor_department_name" },
    { header: "Expense Type", field: "vendor_department_service_titles" },
    { header: "Note", field: "note" },
    { header: "Due Date", field: "due_date" },
    { header: "Amount", field: "amount" },
    { header: "Remaining Amount", field: "remaining_amount" },
    { header: "Payment Method", field: "pay_method" },
    { header: "Status", field: "status" },
  ];

  return (
    <div className="main-container">
      <div className="d-flex p-2">
        <div className="expensecontainer d-flex flex-column pd-2">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h3 className="mb-1">Expense</h3>
            <button className="btn btn-primary cus" onClick={toggleModal}>
              {" "}
              + Add Invoice
            </button>
          </div>

          <div className="table-responsive">
            <ToastContainer />
            <DataTable
              data={expenseInvoice}
              columns={columns}
              showAction={true}
              showFooter={true}
              onEdit={handleEdit}
              onDelete={handleDelete}
              totals={{
                totalPaid,
                totalReceivedCredit,
                totalUnpaid,
                totalOutstandingCredit,
                totalDue,
                totalCredit,
                totalExpense,
              }}
            />
          </div>

          {showModal && (
            <div className="modal-overlay" onClick={toggleModal}>
              <div
                className="modal-container"
                onClick={(e) => e.stopPropagation()}
                style={{
                  width: "700px",
                  backgroundColor: "white",
                  margin: "100px auto",
                  padding: "20px",
                  borderRadius: "8px",
                  position: "relative",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                <div className="modal-header" style={{ border: "none" }}>
                  <h5 className="modal-title" style={{ fontWeight: "bold" }}>
                    {editingInvoiceId ? "Edit Expense" : "Add New Expense"}
                  </h5>

                  <svg
                    onClick={toggleModal}
                    width="14"
                    height="14"
                    marginLeft="20px"
                    marginBottom="20px"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L13 13M13 1L1 13"
                      stroke="black"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="form-row form-group col-md-12 position-relative d-flex">
                      <div className="col-md-6" style={{ paddingRight: "10px" }}>
                        <input
                          style={{
                            backgroundColor: "#0023000C",
                            borderRadius: "10px",
                            height: "52px",
                            paddingRight: "10px"
                          }}
                          type="date"
                          className="form-control"
                          id="billDate"
                          name="date"
                          value={formData.date || today}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-md-6" style={{ paddingRight: "10px" }}>
                        <input
                          style={{
                            backgroundColor: "#0023000C",
                            borderRadius: "10px",
                            height: "52px",
                          }}
                          type="text"
                          className="form-control"
                          name="invoice_no"
                          placeholder="Invoice Number"
                          value={formData.invoice_no}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="d-flex col-md-12">
                      <div className="col-md-6" style={{ paddingRight: "10px" }}>
                        <select
                          style={{
                            backgroundColor: "#0023000C",
                            borderRadius: "10px",
                            height: "52px",
                          }}
                          className="form-control"
                          name="vendor_department"
                          value={formData.vendor_department}
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            if (selectedValue === "add-vendor") {
                              handleShowVendor();
                              setFormData((prevData) => ({
                                ...prevData,
                                vendor_department: "",
                              }));
                            } else {
                              handleChange(e);
                            }
                          }}
                        >
                          <option value="">Select Vendor</option>
                          <option value="add-vendor">Add Vendor</option>
                          {vendors
                            .filter((vendor) => vendor.type === "expense" && !vendor.is_deleted)
                            .map((vendor) => (
                              <option key={vendor.id} value={vendor.id}>
                                {vendor.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-md-6" style={{ paddingRight: "10px" }}>
                        <select
                          className="form-control"
                          style={{
                            backgroundColor: "#0023000C",
                            borderRadius: "10px",
                            height: "52px",
                          }}
                          name="vendor_department_service_titles"
                          value={formData.vendor_department_service_titles}
                          onChange={handleChange}
                          id="expenseType"
                          required
                        >
                          <option value="">Select Expense Type</option>
                          <option value="add-expense">Add Expense Type</option>
                          {filteredExpenseTypes.map((service) => (
                            <option key={service.id} value={service.title}>
                              {service.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="d-flex col-md-12" style={{ paddingTop: "15px" }}>
                      <p>Payment Method : </p>
                      <label className="radio-inline" style={{ paddingLeft: "10px" }}>
                        <input
                          type="radio"
                          name="pay_method_status"
                          value="pay_later"
                          checked={formData.pay_method_status === "pay_later"}
                          onChange={handlePaymentMethodChange}
                        />{" "}
                        Pay Later
                      </label>
                      <label className="radio-inline" style={{ paddingLeft: "10px" }}>
                        <input
                          type="radio"
                          name="pay_method_status"
                          value="pay_now"
                          checked={formData.pay_method_status === "pay_now"}
                          onChange={handlePaymentMethodChange}
                        />{" "}
                        Pay Now
                      </label>
                      <label className="radio-inline" style={{ paddingLeft: "10px" }}>
                        <input
                          type="radio"
                          name="pay_method_status"
                          value="credit_invoice"
                          checked={formData.pay_method_status === "credit_invoice"}
                          onChange={handlePaymentMethodChange}
                        />{" "}
                        Credit Invoice
                      </label>
                    </div>

                    {showPaymentOptions && (
                      <div className="d-flex col-md-6 mb-3">
                        <label className="radio-inline" style={{ paddingLeft: "10px" }}>
                          <input
                            type="radio"
                            name="specificPaymentMethod"
                            value="cash"
                            onChange={handleSpecificPaymentMethodChange}
                          />{" "}
                          Cash
                        </label>
                        <label className="radio-inline" style={{ paddingLeft: "10px" }}>
                          <input
                            type="radio"
                            name="specificPaymentMethod"
                            value="cheque"
                            onChange={handleSpecificPaymentMethodChange}
                          />{" "}
                          Check
                        </label>
                        <label className="radio-inline" style={{ paddingLeft: "10px" }}>
                          <input
                            type="radio"
                            name="specificPaymentMethod"
                            value="bank"
                            onChange={handleSpecificPaymentMethodChange}
                          />{" "}
                          Bank Card/ACH/EFT
                        </label>
                      </div>
                    )}

                    {(formData.pay_method_status === "pay_later" ||
                      formData.pay_method_status === "credit_invoice") && (
                        <div className="col-md-6 d-flex" style={{ paddingRight: "10px" }}>
                          <select
                            className="form-control col-md-6 me-2"
                            name="days"
                            value={formData.days || ""}
                            onChange={handleChange}
                            style={{
                              backgroundColor: "#0023000C",
                              borderRadius: "10px",
                              height: "52px",
                            }}
                            required
                          >
                            <option value="">Select Due Days</option>
                            {dueDays.map((day) => (
                              <option key={day.id} value={day.days}>
                                {day.days}
                              </option>
                            ))}
                          </select>

                          <input
                            type="text"
                            style={{
                              backgroundColor: "#0023000C",
                              borderRadius: "10px",
                              height: "50px",
                            }}
                            className="form-control d-flex col-md-5"
                            id="additionalNotes"
                            placeholder="Notes"
                            name="note"
                            value={formData.note}
                            onChange={handleChange}
                          />
                        </div>
                      )}

                    {showCheckFields && (
                      <div className="d-flex col-md-12 mt-2">
                        <div className="d-flex col-md-4 mt-2">
                          <select
                            style={{
                              backgroundColor: "#0023000C",
                              borderRadius: "10px",
                              height: "52px",
                              marginRight: "10px",
                            }}
                            className="form-control"
                            name="bank"
                            value={formData.bank}
                            onChange={handleChange}
                          >
                            <option value="">Select Bank</option>
                            {bank.map((bank) => (
                              <option key={bank.id} value={bank.id}>
                                {bank.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="d-flex col-md-4 mt-2">
                          <input
                            type="text"
                            className="form-control me-2"
                            placeholder="Check Number"
                            style={{
                              backgroundColor: "#0023000C",
                              borderRadius: "10px",
                              height: "52px",
                              marginRight: "10px",
                            }}
                            id="checkNumber"
                            name="cheque_no"
                            value={formData.cheque_no}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="d-flex col-md-4 mt-2">
                          <input
                            style={{
                              backgroundColor: "#0023000C",
                              borderRadius: "10px",
                              height: "52px",
                            }}
                            className="form-control col-md-3"
                            id="checkNotes"
                            placeholder="Notes"
                          />
                        </div>
                      </div>
                    )}

                    {showBankFields && (
                      <div className="d-flex col-md-12">
                        <div className="d-flex col-md-6 mt-2">
                          <select
                            style={{
                              backgroundColor: "#0023000C",
                              borderRadius: "10px",
                              height: "52px",
                              marginRight: "10px",
                            }}
                            className="form-control"
                            name="bank"
                            value={formData.bank}
                            onChange={handleChange}
                          >
                            <option value="">Select Bank</option>
                            {bank.map((bank) => (
                              <option key={bank.id} value={bank.id}>
                                {bank.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="d-flex col-md-6 mt-2">
                          <input
                            style={{
                              backgroundColor: "#0023000C",
                              borderRadius: "10px",
                              height: "52px",
                            }}
                            className="form-control col-md-3"
                            id="bankNotes"
                            placeholder="Notes"
                            name="notes"
                            value={formData.note}
                          />
                        </div>
                      </div>
                    )}

                    <div className="form-row d-flex col-md-12 mt-2" style={{ marginLeft: "10px" }}>
                      <label>
                        <input
                          type="checkbox"
                          id="prepaidTax"
                          checked={formData.prepaid_tax}
                          onChange={handleCheckboxChange}
                          name="prepaid_tax"
                        />
                        &nbsp; Do you want to add prepaid tax?
                      </label>
                    </div>

                    {formData.prepaid_tax && (
                      <div className="form-row d-flex col-md-6 p-2 mt-2" style={{
                        backgroundColor: "#0023000C",
                        borderRadius: "10px",
                        height: "52px",
                      }}>
                        <div className="input-group col-md-6">
                          <div
                            className="input-group-prepend"
                            style={{
                              background: "#F5F5F5",
                              height: "100%",
                              borderRadius: "10px 0 0 10px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span
                              className="input-group-text prefixtext"
                              style={{
                                border: "none",
                                fontSize: "14px",
                              }}
                            >
                              USD
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control sampletext"
                            placeholder="Prepaid Tax"
                            aria-label="Prepaid Tax"
                            name="prepaid_amount"
                            value={formData.prepaid_amount || ""}
                            onChange={handleChange}
                            onInput={(e) => {
                              // Allow only numbers and a decimal point with two digits after it
                              e.target.value = e.target.value
                                .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                                .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                                .slice(0, 12); // Limit input length (10 digits + 1 decimal + 2 decimal places)
                            }}
                          />
                        </div>
                      </div>
                    )}

                    <div
                      className="form-row d-flex col-md-6 p-2 mt-2"
                      style={{
                        backgroundColor: "#0023000C",
                        borderRadius: "10px",
                        height: "52px",
                      }}
                    >
                      <div className="input-group col-md-6">
                        <div
                          className="input-group-prepend"
                          style={{
                            background: "#F5F5F5",
                            height: "100%",
                            borderRadius: "10px 0 0 10px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span
                            className="input-group-text prefixtext"
                            style={{
                              border: "none",
                              fontSize: "14px",
                            }}
                          >
                            USD
                          </span>
                        </div>
                        <input
                          className="form-control sampletext"
                          placeholder="Amount"
                          aria-label="Amount"
                          aria-describedby="addon-wrapping"
                          name={editingInvoiceId ? "amount" : "amount"}
                          value={
                            editingInvoiceId && formData.pay_amount === undefined
                              ? formData.remaining_amount
                              : formData.amount || formData.amount
                          }
                          onInput={(e) => {
                            // Allow only numbers and a decimal point with two digits after it
                            e.target.value = e.target.value
                              .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                              .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                              .slice(0, 12); // Limit input length (10 digits + 1 decimal + 2 decimal places)
                          }}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </form>

                </div>
                <div className="modal-footer" style={{ border: "none" }}>
                  <button
                    type="button"
                    className="btn btn-secondary-outline"
                    onClick={toggleModal}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <AddVendorModal
        show={showModalVendor}
        handleClose={handleCloseVendor}
        onVendorAdded={handleVendorAdded}
      />
      <AddExpenseTypeModal
        show={showModalExpense}
        handleClose={handleCloseModal}
        onExpenseTypeAdded={handleNewExpenseType}
      />
    </div>
  );
}
